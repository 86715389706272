import gql from 'graphql-tag'

export const eventCodes = gql`
  query eventCodes($companyId: ID!, $lang: String) {
    eventCodes(companyId: $companyId) {
      id
      severity
      description(lang: $lang)
      customNotificationClass(companyId: $companyId) {
        id
        customName
        delays(strict: false)
        baseNotificationClass {
          id
          notificationClass
        }
      }
      defaultNotificationClass {
        id
        notificationClass
        delays
      }
    }
  }
`
