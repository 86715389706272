import gql from 'graphql-tag'
import { allowedLanguages } from '@/translations'

export const eventCodeAdmin = gql`
  query eventCodeAdmin($id: ID!, $lang: String) {
    eventCodeAdmin(eventCodeId: $id) {
      id
      name
      severity
      ${allowedLanguages
        .map((lang) => `${lang}Description: description(lang: "${lang}")`)
        .join('\n')}
      disabled
      detectionPoint {
        id
        name
      }
      detectionMechanism
      terminationPoint
      terminationMechanism
      actionUponDetection
      defaultNotificationClass {
        id
        notificationClass
      }
      coreCauses {
        id
        name
        description(lang: $lang)
      }
      exclusiveCompanyId
      deviceTypes {
        name: deviceTypeName
      }
    }
  }
`

export const eventCodeDeviceTypesUpdate = gql`
  mutation eventCodeDeviceTypesUpdate($eventCodeId: ID!, $deviceTypeNames: [String!]!) {
    eventCodeDeviceTypesUpdate(input: { eventCodeId: $eventCodeId, deviceTypeNames: $deviceTypeNames }) {
      success
    }
  }
`
