import Big from 'big.js'

export function replaceServiceSequenceVariables(text, variables) {
  const variableRegex = /{{\s*('[^']*'|\w*)\s*(?:\|\s*[a-zA-Z]*:\s*[^|}]*\s*)*\s*}}/gm
  const pipeRegex = /\|\s*(?<pipe>[a-zA-Z]*):\s*(?<value>[^|}\s]*)\s*/g

  return text.replaceAll(variableRegex, (match, varName) => {
    // If begins and ends with ', is literal string
    const isLiteralString = varName.startsWith("'") && varName.endsWith("'")
    if (variables?.[varName] != null || isLiteralString) {
      let transformedValue = isLiteralString ? varName.slice(1, -1) : variables[varName].toString()
      let pipeMatch
      while ((pipeMatch = pipeRegex.exec(match)) !== null) {
        if (pipeMatch.groups.pipe === 'digits') {
          const parsedDigits = parseInt(pipeMatch.groups.value)
          if (!isNaN(parsedDigits) && parsedDigits >= 0 && parsedDigits <= 20) {
            transformedValue = Number(transformedValue).toFixed(parsedDigits)
          }
        } else if (pipeMatch.groups.pipe === 'multiplier') {
          const parsedMultiplier = Number(pipeMatch.groups.value)
          if (!isNaN(parsedMultiplier)) {
            transformedValue = new Big(transformedValue).times(parsedMultiplier).toString()
          }
        } else if (pipeMatch.groups.pipe === 'if') {
          // Usage: {{ value | if: varName }} - if varName exists and is true, return value, otherwise return empty string
          const varName = pipeMatch.groups.value
          // If it doesn't exist or is interpreted as false, return empty string
          if (variables?.[varName] == null || !variables?.[varName]) {
            return ''
          }
        } else if (pipeMatch.groups.pipe === 'ifnot') {
          // Usage: {{ value | ifnot: varName }} - if varName exists and is false, return value, otherwise return empty string
          const varName = pipeMatch.groups.value
          // If it doesn't exist or is interpreted as true, return empty string
          if (variables?.[varName] == null || variables?.[varName]) {
            return ''
          }
        } else {
          console.warn(`Unknown service sequence variable modifier '${pipeMatch.groups.pipe} '`)
        }
      }

      return transformedValue
    }
    return '---'
  })
}
