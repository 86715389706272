<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" width="unset">
    <v-card>
      <div class="pa-6">
        <h2 class="mb-6">{{ heading }}</h2>
        <slot
          :validations="$v"
          :value="value"
          :onInput="onInput"
          :errors="validations ? getErrors('value') : []"
        ></slot>
        <div class="mt-8 text-right">
          <v-btn @click="$emit('modalToggle', false)" depressed>{{ closeBtnText || $t('global.close') }}</v-btn>
          <v-btn v-if="canDelete" @click="onDeleteBtn()" color="error" class="ml-3" depressed>{{
            deleteBtnText || $t('global.delete')
          }}</v-btn>
          <v-btn
            @click="submitIfValid()"
            :disabled="value === originalValue"
            color="primary"
            class="ml-3"
            depressed
            >{{ submitBtnText || $t('global.submit') }}</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'

export default {
  name: 'EditValueModal',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    heading: String,
    validations: Object,
    originalValue: null,
    submit: Function,
    canDelete: Boolean,
    closeBtnText: String,
    deleteBtnText: String,
    submitBtnText: String,
  },
  data() {
    return {
      value: this.originalValue,
    }
  },
  validations() {
    return this.validations || {}
  },
  methods: {
    onInput(newValue) {
      this.$v.value?.$touch()
      this.value = newValue
    },
    async submitIfValid() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.submit(this.value)
      }
    },
    async onDeleteBtn() {
      await this.submit(null)
    },
  },
  watch: {
    isOpen() {
      this.value = this.originalValue
    },
  },
}
</script>
<style lang="less" scoped></style>
