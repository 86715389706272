import gql from 'graphql-tag'

export const companies = gql`
  query companies(
    $search: NonEmptyString
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    companies(search: $search, first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          id
          name
          xeeloIntegration
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

export const allCompanies = gql`
  query allCompanies {
    companies: allCompanies {
      id
      name
    }
  }
`

export const companiesOptions = gql`
  query companiesOptions {
    companies {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
