export default {
  controllerId: 'Geräte-ID (XXX-XXX-XXX)',
  controller: 'Gerät',
  status: {
    ONLINE: 'Online',
    OFFLINE: 'Offline',
  },
  operatingMode: {
    IN_CONSTRUCTION: 'Im Bau',
    NORMAL: 'Normalbetrieb',
    MAINTENANCE: 'Wartung',
    WINTERIZED: 'Voll eingewintert',
  },
  lastSynchronization: 'Zuletzt gesehen',
  propagate: 'Gerät neu starten.',
  saveAndPropagate: 'Speichern und senden',
  saveAsDeviceTemplate: 'Als Geräte-Vorlage speichern',
  saveAsDeviceTemplateDescription: 'Name der Vorlage eingeben.',
  saveWithoutSending: 'Änderungen speichern, ohne sie an das Gerät zu senden?',
  validations: {
    is_empty: 'Bitte füllen Sie dieses Feld aus.',
    device_already_registered: 'Diese Geräte-ID existiert bereits in der Datenbank.',
    invalid_serial: 'Diese Geräte-ID existiert nicht.',
  },
  searchDevice: 'Gerät suchen',
  addDevice: 'Gerät hinzufügen',
  addDeviceInProgress: 'In Bearbeitung',
  target: 'Ziel',
  deviceNotFound: 'Gerät nicht gefunden.',
  unavailable: 'Nicht verfügbar',
  forbiddenByUser: 'Vom Benutzer verboten ',
  enterExpertMode: 'Experten-Modus aktivieren',
  forbidden: 'Verboten',
  dosing: 'Dosierung',
  notDosing: 'Bereit',
  addressNotFound: 'Adresse nicht gefunden.',
  diagnosticCharts: 'Diagnostische Diagramme',
  chartsUnderConstruction: 'Dieser Bereich befindet sich in der Entwicklung. Fortfahren.',
  chartsUnavailable: 'Diagnostische Diagramme sind für diesen Gerätetyp nicht verfügbar.',
  controlUpdateFail: 'Dateiaktualisierung ist fehlgeschlagen.',
  sendSettingsFailed: 'Fehler beim Senden der Einstellungen an das Gerät.',
  saveDeviceTemplateSuccess: 'Vorlage wurde erfolgreich gespeichert.',
  saveDeviceTemplateError: 'Beim Speichern dieser Vorlage ist ein Fehler aufgetreten.',
  confirmOpenExpertModeModal: {
    headline: 'Sind Sie sicher, dass Sie den Experten-Modus aktivieren möchten?',
    text: 'Unprofessionelle Verwendung des Experten-Modus kann das Gerät beschädigen.',
  },
  confirmCloseExpertModeModal: {
    headline: 'Sind Sie sicher, dass Sie den Experten-Modus verlassen möchten?',
    text: 'Systemsteuerung kehrt in den normalen Modus zurück.',
  },
  expertModeModal: {
    headline: 'Experten-Modus aktiviert.',
    exit: 'Experten-Modus beenden',
    openFailed: 'Fehler beim Aktivieren des Experten-Modus.',
    closeFailed: 'Fehler beim Deaktivieren des Experten-Modus.',
  },
  deviceWentOfflineModal: {
    headline: 'Das Gerät ist offline gegangen.',
    text: 'Warten Sie, bis sich das Gerät erneut verbindet oder verlassen Sie den Experten-Modus.',
    buttonText: 'Experten-Modus beenden',
  },
  deviceList: 'Geräteliste',
  listView: 'Listenansicht',
  mapView: 'Kartenansicht',
  deviceListHeaders: {
    name: 'Name',
    company: 'Unternehmen',
    connectionType: 'Verbindungstyp',
    connection: 'Verbindungsstatus',
    currentOperation: 'Aktueller Betrieb ',
    operatingMode: 'Betriebsmodus',
    reports: 'Nachrichten',
    userNote: 'Benutzernotiz',
    inOperationSince: 'In Betrieb seit',
  },
  noDevices: 'Keine verfügbaren Geräte',
  noRecords: 'Keine Aufzeichnungen',
  timeSchedule: {
    changeSchedule: 'Wochenplan ändern',
    selectSchedule: 'Wochenplan auswählen',
    showWeeklyTimeSchedules: 'Wochenpläne anzeigen',
    showDailyTimeSchedules: 'Tagespläne anzeigen',
    recommended: 'Empfohlene Laufzeit',
    activeTimeSchedule: 'Aktiver Zeitplan',
    noActiveTimeSchedule: 'Kein aktiver Zeitplan.',
    activeTimeScheduleNoMatch:
      'Kein aktiver Zeitplan erkannt. Bitte aktivieren Sie einen Ihrer Zeitpläne.',
    pleaseActivate: 'Bitte aktivieren Sie einen Ihrer Zeitpläne.',
    setActiveTimeScheduleFailed: 'Aktivieren des Zeitplans fehlgeschlagen.',
    setActiveTimeScheduleFailedTooLarge: 'Schedule is too complex. Please simplify it.',
    createTimeScheduleFailed: 'Erstellen des Zeitplans fehlgeschlagen.',
    updateTimeScheduleFailed: 'Aktualisieren des Zeitplans fehlgeschlagen.',
    deleteTimeScheduleFailed: 'Löschen des Zeitplans ist fehlgeschlagen.',
    setActiveTimeScheduleSuccess: 'Zeitplan erfolgreich aktiviert.',
    createTimeScheduleSuccess: 'Zeitplan erfolgreich erstellt.',
    updateTimeScheduleSuccess: 'Zeitplan erfolgreich aktualisiert.',
    deleteTimeScheduleSuccess: 'Zeitplan erfolgreich gelöscht.',
    editModal: {
      cancelConfirmation: {
        heading: 'Bearbeitung abbrechen',
        text: 'Sind Sie sicher, dass Sie alle Änderungen verwerfen möchten?',
      },
    },
    confirmActivateModal: {
      heading: 'Zeitplan aktivieren',
      text: 'Sind Sie sicher, dass Sie diesen Zeitplan aktivieren möchten?',
    },
  },
  gallery: {
    noPhotodocumentation: 'Keine Bilder hinzugefügt.',
    addPhotos: 'Bilder hinzufügen',
    takePhoto: 'Foto aufnehmen',
    imageUploadSuccess: 'Bilder erfolgreich hochgeladen.',
    imageUploadFailed: 'Hochladen der Bilder fehlgeschlagen.',
    imageUploadPartiallyFailed: 'Fehler beim Upload von {failedCount} von {totalCount} Bildern.',
    imageUploadUnsupportedFormat:
      'Nicht unterstütztes Dateiformat. Bitte verwenden Sie JPG, PNG, HEIF oder HEIC.',
    deleteConfirmationHeading: 'Bild löschen',
    deleteConfirmationText: 'Sind Sie sicher, dass Sie dieses Bild löschen möchten?',
    deleteImageSuccess: 'Bild erfolgreich gelöscht.',
    deleteImageFailed: 'Löschen des Bildes fehlgeschlagen.',
    imageNameEditHeading: 'Bild Name',
    imageNameEditText: 'Neuen Bildnamen eingeben.',
    imageNameEditLabel: 'Bild Name',
    renameImageSuccess: 'Bild Name erfolgreich aktualisiert.',
    renameImageFailed: 'Aktualisierung des Bildnamens ist fehlgeschlagen.',
  },
  deviceDeleteSuccess: 'Gerät erfolgreich entfernt.',
  deviceDeleteFailure: 'Löschen des Geräts fehlgeschlagen.',
  deviceDeleteConfirmation: {
    heading: 'Gerät löschen',
    text: 'Sind Sie sicher, dass Sie dieses Gerät ({controller}) entfernen möchten?',
  },
  updateDevice: 'Geräteeigenschaften ändern',
  deviceUpdateSuccess: 'Geräteeigenschaften wurden erfolgreich geändert.',
  deviceUpdateFailure: 'Ändern der Geräteeigenschaften fehlgeschlagen.',
  deviceUpdateControllerTaken: 'Diese Geräte-ID ist bereits von einem anderen Gerät belegt.',
  serviceSequenceInProgress: 'Service-Sequenz in Arbeit.',
  nextRun: 'Nächster Lauf',
  launch: 'Start',
  insufficientPermissionsToViewServiceSequence:
    'Unzureichende Berechtigungen zur Anzeige der Service-Sequenz.',
}
