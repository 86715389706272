export default {
  menu: {
    pools: 'Pools',
    sewagePumps: 'Hebeanlagen',
    chargePoint: 'Ladepunkte',
    companyManagement: 'Unternehmen',
    templates: 'Vorlagen',
    admin: 'Admin',
    productCatalogue: 'Produkte',
    inviteCompany: 'Unternehmen einladen',
    logout: 'Logout',
  },
  deviceTypes: {
    POOL: 'Pool',
    SEWAGE_PUMP: 'Hebeanlage',
    CHARGE_POINT: 'Ladepunkte',
  },
  device: 'Gerät',
  devices: 'Geräte',
  basicInformation: 'Basisdaten',
  operatingValues: 'Betriebsdaten',
  controls: 'Steuerung',
  language: 'Sprache',
  lang: {
    cs: 'Čeština',
    en: 'English',
    de: 'Deutsch',
    pl: 'Polski',
  },
  daysOfWeek: {
    MONDAY: 'Montag',
    TUESDAY: 'Dienstag',
    WEDNESDAY: 'Mittwoch',
    THURSDAY: 'Donnerstag',
    FRIDAY: 'Freitag',
    SATURDAY: 'Samstag',
    SUNDAY: 'Sonntag',
  },
  daysOfWeekShort: {
    MONDAY: 'Mo',
    TUESDAY: 'Di',
    WEDNESDAY: 'Mi',
    THURSDAY: 'Do',
    FRIDAY: 'Fr',
    SATURDAY: 'Sa',
    SUNDAY: 'So',
  },
  reset: 'Zurücksetzen',
  save: 'Speichern',
  delete: 'Löschen',
  cancel: 'Abbrechen',
  discard: 'Abbrechen',
  back: 'Zurück',
  continue: 'Weiter',
  confirm: 'Bestätigen',
  enter: 'Eingeben',
  accept: 'Zustimmen',
  close: 'Schließen',
  submit: 'Senden',
  edit: 'Bearbeiten',
  unassign: 'Zuweisung aufheben',
  add: 'Hinzufügen',
  remove: 'Entfernen',
  clear: 'Löschen',
  manage: 'Verwalten',
  view: 'Anzeigen',
  abort: 'Abbruch',
  ok: 'OK',
  hide: 'Ausblenden',
  id: 'ID',
  email: 'E-Mail',
  name: 'Name',
  title: 'Name',
  firstName: 'Vorname',
  lastName: 'Nachname',
  contactName: 'Kontaktperson',
  contactEmail: 'Kontakt E-Mail',
  contactPhone: 'Kontakt-Telefon',
  companyName: 'Unternehmensname',
  phoneNumber: 'Telefon',
  description: 'Beschreibung',
  enabled: 'Aktiv',
  deactivated: 'Deaktiviert',
  contactInfo: 'Kontaktinformation',
  address: 'Adresse',
  billingAddress: 'Rechnungsadresse',
  note: 'Notiz',
  set: 'Einstellen',
  location: 'GPS-Koordinaten',
  map: 'Karte',
  settings: 'Konfiguration',
  template: 'Vorlage',
  deviceName: 'Gerätename',
  status: 'Auftragsstatus',
  state: 'Status',
  defaultTimeSchedule: 'Standard-Zeitplan',
  myDevices: 'Meine Geräte',
  product: 'Produkt',
  endUserProduct: 'Endbenutzer-Produkt',
  professionalUserProduct: 'Professionelles Benutzerprodukt',
  customer: 'Kunde',
  company: 'Unternehmen',
  type: 'Typ',
  companyAccess: 'Unternehmenszugang',
  myAccess: 'Mein Zugriff',
  password: {
    notSame: 'Passwörter stimmen nicht überein.',
    notValid:
      'Passwort muss mindestens 8 Zeichen, 1 Zahl, Klein- und Großbuchstaben und mindestens ein Sonderzeichen enthalten.',
  },
  countryCodes: {
    al: 'Albanien',
    ad: 'Andorra',
    am: 'Armenien',
    at: 'Österreich',
    az: 'Aserbaidschan',
    by: 'Weißrussland',
    be: 'Belgien',
    ba: 'Bosnien und Herzegowina',
    bg: 'Bulgarien',
    hr: 'Kroatien',
    cy: 'Zypern',
    cz: 'Tschechien',
    dk: 'Dänemark',
    ee: 'Estland',
    fi: 'Finnland',
    fr: 'Frankreich',
    ge: 'Georgien',
    de: 'Deutschland',
    gr: 'Griechenland',
    hu: 'Ungarn',
    is: 'Island',
    ie: 'Irland',
    it: 'Italien',
    kz: 'Kasachstan',
    lv: 'Lettland',
    li: 'Liechtenstein',
    lt: 'Litauen',
    lu: 'Luxemburg',
    mt: 'Malta',
    md: 'Moldawien',
    mc: 'Monaco',
    me: 'Montenegro',
    nl: 'Niederlande',
    mk: 'Nordmazedonien',
    no: 'Norwegen',
    pl: 'Polen',
    pt: 'Portugal',
    ro: 'Rumänien',
    ru: 'Russland',
    sm: 'San Marino',
    rs: 'Serbien',
    sk: 'Slovakei',
    si: 'Slowenien',
    es: 'Spanien',
    se: 'Schweden',
    ch: 'Schweiz',
    tr: 'Türkei',
    ua: 'Ukraine',
    gb: 'Großbritannien',
    va: 'Vatikanstadt (Heiliger Stuhl)',
  },
  addressItems: {
    route: 'Installationsort',
    city: 'Stadt',
    postalCode: 'PLZ',
    country: 'Land',
    lat: 'Breitengrad',
    lng: 'Längengrad',
  },
  addressSpecification: 'Adressinformationen',
  addressHint: 'Wählen Sie eine Adresse oder geben Sie sie manuell ein.',
  yes: 'Ja',
  no: 'Nein',
  errors: {
    emailAlreadyExists:
      'Es existiert bereits ein Konto mit dieser E-Mail-Adresse. Bitte melden Sie sich mit der Methode an, die Sie für die Registrierung verwendet haben.',
    invalidInvitation: 'Einladung ist abgelaufen oder ungültig.',
    userDeactivated:
      'Konto wurde deaktiviert. Um Ihr Konto zu reaktivieren, kontaktieren Sie uns bitte unseren technischen Support.',
    somethingWentWrong:
      'Ein Fehler ist aufgetreten. Versuchen Sie es erneut oder wenden Sie sich an den technischen Support.',
  },
  newDeviceResetConfirmationModal: {
    heading: 'Formular zurücksetzen',
    text: 'Sind Sie sich sicher, dass Sie die Standardwerte wiederherstellen möchten?',
  },
  companyInvite: {
    inviteCompany: 'Unternehmen einladen',
    description:
      'Geben Sie die E-Mail-Adresse des Administrators des Unternehmens ein, das Sie einladen möchten.',
    submit: 'Einladung versenden',
    invitationSuccess: 'Einladung erfolgreich gesendet.',
    invitationError: 'Senden der Einladung ist fehlgeschlagen.',
    sentInvitations: 'Einladung versenden',
    resendInvitation: 'Erneut senden',
  },
  createCompanyModal: {
    headline: 'Neues Unternehmes anlegen',
    description: 'Geben Sie den Unternehmensnamen ein.',
    companyName: 'Unternehmensname',
    successMessage: 'Das Unternehmen wurde erfolgreich erstellt.',
    errorMessage: 'Die Erstellung des Unternehmens ist fehlgeschlagen.',
    companyNameTakenError:
      'Der Unternehmensname ist bereits vergeben. Bitte wählen Sie einen anderen Namen.',
  },
  formValidation: {
    required: 'Pflichtfeld',
    notPhoneNumber: 'Geben Sie eine gültige Telefonnummer inkl. Landesvorwahl ein.',
    notEmail: 'Geben Sie eine gültige E-Mail-Adresse an.',
    notSameAsPassword: 'Passwörter stimmen nicht überein.',
    tooLong: 'Maximale Passwortlänge überschritten.',
    max: 'Wert muss kleiner als oder gleich {max} sein',
    min: 'Wert muss größer als oder gleich {min} sein',
    integer: 'Der Wert muss eine ganze Zahl sein.',
  },
  pageNotFound: 'Seite nicht gefunden.',
  insufficientPermissions: 'Unzureichende Berechtigungen.',
  unsavedChanges:
    'Nicht gespeicherte Änderungen. Möchten Sie die Änderungen wirklich verlassen und verwerfen?',
  on: 'An',
  off: 'Aus',
  copiedToClipboard: 'In die Zwischenablage kopiert.',
  copyToClipboardFailed: 'Kopieren in die Zwischenablage fehlgeschlagen.',
  all: 'Alle',
  doFilter: 'Filter',
  schedule: 'Zeitplan',
  schedules: 'Zeitpläne',
  hrs: 'Std.',
  dataUpdateSuccess: 'Daten wurden erfolgreich aktualisiert.',
  dataUpdateFailure: 'Aktualisierung der Daten ist fehlgeschlagen.',
  invalidInvitationModal: {
    header: 'Ungültige Einladung.',
    text: 'Einladung ist abgelaufen oder ungültig.',
  },
  acceptCompanyToCompanyInvitationModal: {
    header: 'Einladung zum Unternehmen',
    text: 'Ihr Unternegmen, {childCompanyName}, wurde zum Unternehmen {parentCompanyName} eingeladen. Durch die Annahme dieser Einladung wird {childCompanyName} ein Servicepartner für {parentCompanyName}. Möchten Sie diese Einladung annehmen?',
  },
  acceptCompanyToCompanyInvitation: {
    success: 'Einladung erfolgreich angenommen.',
    failure: 'Annahme der Einladung ist fehlgeschlagen.',
  },
  templateManagement: 'Vorlagen-Management',
  addTemplate: 'Vorlage hinzufügen',
  emptyTemplate: 'Leere Vorlage',
  dataTable: {
    itemsPerPageText: 'Reihen pro Seite:',
    allItemsText: 'Alle',
    of: 'von',
  },
  anonymous: 'Anonym',
  adminConsole: 'Administrator-Konsole',
  adminConsoleMenu: {
    users: 'Benutzer',
    devices: 'Geräte',
    productCatalogue: 'Produktkatalog',
    companies: 'Firmen',
    serviceHints: 'Service-Hinweise',
    notificationDispatcher: 'Nachrichten',
    thingTypes: 'Gerätetypen',
  },
  productCatalogueMenu: {
    devices: 'Geräte',
    customers: 'Kunden',
    endUserProducts: 'Endbenutzer-Produkte',
    professionalUserProducts: 'Professionelle Benutzer-Produkte',
  },
  addCustomer: 'Kunde hinzufügen',
  addEndUserProduct: 'Endbenutzer-Produkt hinzufügen',
  addProfessionalUserProduct: 'Professionelles Benutzer-Produkt hinzufügen',
  customerCreate: {
    success: 'Kunde erfolgreich erstellt.',
    failure: 'Erstellen der Kunde ist fehlgeschlagen.',
  },
  customerUpdate: {
    success: 'Kundendaten erfolgreich aktualisiert.',
    failure: 'Die Bearbeitung der Kundendaten ist fehlgeschlagen.',
  },
  customerDetail: 'Kunde-Detail',
  customerNotFound: 'Kunde nicht gefunden.',
  editCustomerData: 'Kundendaten bearbeiten',
  deviceType: 'Gerätetyp',
  eligibilities: 'Genehmigungen',
  productCreate: {
    success: 'Produkt erfolgreich erstellt.',
    failure: 'Erstellen des Produkt ist fehlgeschlagen.',
  },
  days: 'Tage',
  hours: 'Stunden',
  minutes: 'Minuten',
  thingType: 'Gerätetyp',
  selectThingTypesHint: 'Wählen Sie einen oder mehrere Gerätetypen aus.',
  endUserProductDetail: 'Endbenutzer-Produktdetail',
  professionalUserProductDetail: 'Professioneller Benutzer-Produktdetail',
  productNotFound: 'Produkt nicht gefunden',
  defaultProductForType: 'Standard-Produkt für {thingType}',
  defaultProduct: 'Standard-Produkt',
  systemProduct: 'System-Produkt',
  editProduct: 'Produkt bearbeiten',
  productUpdate: {
    success: 'Produkt erfolgreich aktualisiert.',
    failure: 'Aktualisieren des Produkt ist fehlgeschlagen.',
  },
  addDevice: 'Gerät hinzufügen',
  assignDeviceToCustomer: 'Gerät einem Kunden zuweisen',
  deviceAssignCustomerAndProduct: {
    success: 'Gerät erfolgreich einem Kunden zugewiesen.',
    failure: 'Fehler beim Zuweisen des Geräts.',
  },
  deviceRemoveCustomerAndProduct: {
    success: 'Gerät erfolgreich vom Kunden entfernt.',
    failure: 'Fehler beim Entfernen des Geräts.',
  },
  deviceUnassignConfirmation: {
    heading: 'Gerät vom Kunden entfernen',
    text: 'Sind Sie sicher, dass Sie "{deviceName}" ({controller}) vom Kunden entfernen möchten?',
  },
  changeProduct: 'Produkt ändern',
  deviceChangeProduct: {
    success: 'Produkt erfolgreich geändert.',
    failure: 'Anderung des Produkt ist fehlgeschlagen.',
  },
  customerDeleteConfirmation: {
    heading: 'Kunde löschen',
    text: 'Sind Sie sicher, dass Sie den Kunden {customerName} ({email} ) löschen möchten?',
  },
  deleteCustomer: {
    success: 'Kunde erfolgreich gelöscht.',
    failure: 'Löschen des Kunden ist fehlgeschlagen.',
  },
  transferDevices: 'Geräte auf anderen Kunden übertragen',
  doNotTransferDevices: 'Geräte nicht übertragen',
  productDeleteConfirmation: {
    heading: 'Produkt löschen',
    text: "Sind Sie sicher, dass Sie das Produkt '{productName} ' löschen möchten?",
  },
  deleteProduct: {
    success: 'Produkt erfolgreich gelöscht.',
    failure: 'Löschen des Produkts ist fehlgeschlagen.',
  },
  transferDevicesProduct: 'Geräte zum anderen Produkt übertragen',
  transferToDefaultProduct: 'Ding zum Standard-Produkt übertragen',
  setAsDefaultProduct: 'Produkt als Standard festlegen',
  adminProductCatalogueMenu: {
    companyEligibilities: 'Unternehmens-Genehmigungen',
    deviceTypeEligibilities: 'Gerätetypfähigkeiten',
    companyAllowedDeviceTypes: 'Erlaubte Gerätetypen für Unternehmen',
    generalEligibilities: 'Allgemeine Genehmigungen',
  },
  companiesThingTypesUpdate: {
    success: 'Gerätetypberechtigungen erfolgreich aktualisiert.',
    failure: 'Fehler beim Aktualisieren der Gerätetypberechtigungen.',
  },
  allowed: 'Erlaubt',
  endUserDefaultValue: 'Standardwert für Endbenutzer',
  professionalUserDefaultValue: 'Standardwert für professionellen Benutzer',
  generalEligibility: 'Allgemeine Förderfähigkeit',
  updateEligibility: 'Genehmigung aktualisieren',
  eligibility: 'Genehmigung',
  thingTypesEligibilitiesUpdate: {
    success: 'Genehmigung erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Förderfähigkeit ist fehlgeschlagen.',
  },
  companyAllowedEligibilitiesUpdate: {
    success: 'Erlaubte Genehmigungen für Unternehmen erfolgreich aktualisiert.',
    failure: 'Die Aktualisierung der zulässigen Genehmigungen für Unternehmen ist fehlgeschlagen.',
  },
  addEligibility: 'Genehmigung hinzufügen',
  deviceEligibilityCreate: {
    success: 'Genehmigung erfolgreich erstellt.',
    failure: 'Erstellungung der Förderfähigkeit ist fehlgeschlagen.',
  },
  generalEligibilitiesUpdateDefaultValues: {
    success: 'Standardwerte der allgemeinen Genehmigungen erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Werte der allgemeinen Förderungsmöglichkeiten ist fehlgeschlagen.',
  },
  eligibilityDeleteConfirmation: {
    heading: 'Genehmigung löschen',
    text: "Sind Sie sicher, dass Sie die Förderfähigkeit '{eligibility}' löschen möchten?",
  },
  eligibilityDelete: {
    success: 'Genehmigung erfolgreich gelöscht.',
    failure: 'Löschen der Förderfähigkeit ist fehlgeschlagen.',
  },
  addAccess: 'Zugang hinzufügen',
  addAccessConfirmation: {
    heading: 'Servicezugang hinzufügen',
    text: 'Sind Sie sicher, dass Sie Ihrem Unternehmen {childCompany} uneingeschränkten Zugriff auf {parentCompany} ermöglichen möchten?',
  },
  addCurrentCompanyAsServiceCompany: {
    success: 'Unternehmen erfolgreich hinzugefügt.',
    failure: 'Das Hinzufügen des Unternehmens ist fehlgeschlagen.',
  },
  removeAccess: 'Zugriff entfernen',
  removeAccessConfirmation: {
    heading: 'Service-Zugriff entfernen',
    text: 'Sind Sie sicher, dass Sie den Zugriff Ihres Unternehmens {childCompany} auf die Dinge von {parentCompany} entfernen möchten?',
  },
  removeCurrentCompanyAsServiceCompany: {
    success: 'Service-Zugriff erfolgreich entfernt.',
    failure: 'Entfernen des Service-Zugriffs ist fehlgeschlagen.',
  },
  addMyselfToCompanyConfirmation: {
    heading: 'Administrator-Zugriff zur Firma hinzufügen',
    text: 'Sind Sie sicher, dass Sie sich selbst Administrator-Zugriff auf das Unternegmen {companyName} gewähren möchten?',
  },
  addMyselfToCompany: {
    success: 'Administrator-Zugriff erfolgreich gewährt.',
    failure: 'Gewährung von Administrator-Zugriff ist fehlgeschlagen.',
  },
  removeMyselfFromCompanyConfirmation: {
    heading: 'Zugriff auf das Unternehmen entfernen',
    text: 'Sind Sie sicher, Ihren Zugriff auf das Unternehmen {companyName} zu entfernen?',
  },
  removeMyCompanyAccess: {
    success: 'Zugriff erfolgreich entfernt.',
    failure: 'Entfernen des Zugriffs ist fehlgeschlagen.',
  },
  invitationState: {
    accepted: 'AKZEPTIERT',
    expired: 'ABGELAUFEN',
    pending: 'BESTÄTIGUNG AUSSTEHEND',
  },
  createCompanyInvitationDeleteConfirmation: {
    heading: 'Einladung löschen',
    text: 'Sind Sie sicher, dass Sie die an {email} gesendete Einladung löschen möchten?',
  },
  createCompanyInvitationDelete: {
    success: 'Einladung erfolgreich gelöscht.',
    failure: 'Löschen der Einladung ist fehlgeschlagen.',
  },
  resendCooldownTooltip: 'Die Vorgang ist in {remainingTime} Sekunden wieder verfügbar.',
  newElegibilityWarning:
    'Neue Genehmigung. Diese Genehmigung existiert nicht, wenn das Produkt erstellt wurde und daher Standardwert hat. Diese Benachrichtigung wird nach Produktupdates verschwinden.',
  invitationLanguage: 'Einladungssprache',
  userAccessesTab: {
    deviceAdmin: 'Geräteadministrator',
    guests: 'Gast',
    demoTokens: 'Demo-Tokens',
    guest: 'Gast',
    activeDeviceAdminInvitation: 'Geräte-Administrator Einladung gesendet',
    activeInvitation: 'Einladungen verschickt',
    noDeviceAdmin: 'Das Gerät hat derzeit keinen Administrator.',
    noGuests: 'Keine Gäste.',
    noTokens: 'Keine Tokens.',
    inviteUser: 'Benutzer einladen',
    changeDeviceAdmin: 'Gerät einem neuen Administrator übertragen',
    changeDeviceAdminModal: {
      headline: 'Gerät einem neuen Administrator übertragen',
      text: 'Dies ändert Ihre Rolle sofort an Gäste und sendet eine Einladungs-E-Mail an den neuen Ding-Administrator.',
      selectExistingUser: 'Einen neuen Administrator von Gastbenutzern auswählen.',
      enterEmail: 'Enter email address.',
    },
    confirmChangeDeviceAdminModal: {
      headline:
        'Sind Sie sicher, dass Sie das Gerät an einen neuen Administrator übertragen möchten?',
      text: 'Sind Sie sicher, dass Sie die Administratorrolle für das Gerät {deviceName} an den Benutzer mit der E-Mail-Adresse {invitationEmail} übertragen möchten? Dieser Vorgang ändert sofort Ihre Rolle in Gast und sendet eine Einladungs-E-Mail an den neuen Geräteadministrator.',
    },
    inviteUserModal: { headline: 'Neuen Benutzer einladen' },
    confirmDeleteAccessModal: {
      headline: 'Benutzerzugang löschen',
      text: 'Sind Sie sicher, dass Sie den Zugriff auf {deviceName} für {email} entfernen möchten?',
    },
    editModal: {
      text: 'Beim Ändern der Rolle von Gast zu Administrator wird der vorherige Administrator zu Gast geändert. Ein Gerät kann nur einen Administrator haben.',
      role: 'Rolle',
      removeUsersAccess: 'Meinen Zugang löschen',
    },
    removeOwnAccessModal: {
      headline: 'Meinen Zugang löschen',
      text: 'Möchten Sie die Rolle des Geräteadministrators an jemand anderen übertragen?',
      deleteWithTransfer: 'Benutzer auswählen',
      deleteWithoutTransfer: 'Löschen ohne Übertragung',
      selectExistingUser: 'Einen neuen Administrator von Gastbenutzern auswählen.',
      enterEmail: 'E-Mail Adresse eingeben',
    },
    confirmRemoveOwnAccessModal: {
      headline: 'Meinen Zugang löschen',
      text: "Sind Sie sicher, dass Sie Ihren Zugriff auf das Gerät '{deviceName}' löschen möchten, während Sie die Administratorrolle an {invitationEmail} übertragen?",
    },
    removeWithoutTransferConfirmationModal: {
      headline: 'Meinen Zugriff ohne Übertragung löschen',
      text: "Sind Sie sicher, dass Sie Ihren Zugriff auf das Gerät '{deviceName}' löschen möchten, ohne die Administratorrolle an einen anderen Benutzer zu übertragen?",
    },
    addDemoTokenModal: {
      headline: 'Demo-Token hinzufügen',
      submitBtnText: 'Token hinzufügen',
    },
    inviteUserAction: {
      success: 'Benutzer erfolgreich eingeladen.',
      failure: 'Senden der Einladung ist fehlgeschlagen.',
      alreadyInvited: 'Benutzer ist bereits eingeladen.',
      alreadyHasAccess: 'Benutzer hat bereits Zugriff.',
    },
    transferDeviceAdminAction: {
      success: 'Neuer Geräteadministrator erfolgreich eingeladen.',
      failure: 'Fehler beim Übertragen des Geräts auf den neuen Administrator',
      alreadyDeviceAdmin: 'Dieser Benutzer ist bereits der Geräteadministrator.',
    },
    deleteUserAccessAction: {
      success: 'Benutzerzugang erfolgreich gelöscht.',
      failure: 'Löschen der Benutzerrechten ist fehlgeschlagen.',
    },
    changeAccessTypeAction: {
      success: 'Die Rolle des Benutzers wurde erfolgreich geändert.',
      failure: 'Aktualisierung des Benutzerrollen ist fehlgeschlagen.',
    },
    createDemoTokenAction: {
      success: 'Demo-Token erfolgreich erstellt.',
      failure: 'Erstellen des Demo-Token ist fehlgeschlagen.',
    },
    wifiPassword: 'WLAN-Zugangsdaten',
  },
  troubleshooting: 'Fehlerbehebung',
  possibleCauses: 'Mögliche Problemquellen',
  correctiveMeassures: 'Vorgeschlagene Maßnahmen',
  changeServiceCompanyModal: {
    title: 'Serviceunternehmen ändern',
    description:
      '\nGeben Sie die E-Mail-Adresse des Administrators des Unternehmens ein, das Sie einladen möchten. Die Einladung wird an diese E-Mail-Adresse gesendet. Ihr Serviceunternehmen wird nur dann gewechselt, wenn das Unternehmen Ihre Einladung akzeptiert.\n\n\n\nRegenerate response',
    activeInvitationDescription:
      'Dieses Gerät hat eine aktive Einladung an ein neues Serviceunternehmen. Bitte löschen Sie die Einladung, um eine Einladung an ein neues Unternehmen zu senden.',
    submit: 'Einladung senden',
    resendInvitation: 'Einladung erneut senden',
    deleteInvitation: 'Einladung löschen',
    activeInvitation: 'Aktive Einladung',
    invitationWillExpire: 'Einladung wird ablaufen',
    inviteServiceCompanyAction: {
      success: 'Das Serviceunternehmen wurde erfolgreich eingeladen.',
      failure: 'Fehler beim Senden der Einladung an Serviceunternehmen.',
      notAdminEmail:
        'Die von Ihnen eingegebene E-Mail-Adresse gehört keinem Unternehmensadministrator.',
      alreadyServiceCompany: 'Das Unternehmen ist bereits das Serviceunternehmen für dieses Gerät.',
    },
    resendInvitationSuccess: 'Invitation successfully resent.',
    deleteServiceCompanyInvitationAction: {
      success: 'Einladung erfolgreich gelöscht.',
      failure: 'Löschen der Einladung ist fehlgeschlagen.',
    },
  },
  serviceCompanyInvitationAcceptationModal: {
    title: 'Einladung zum Servicezugriff auf das Gerät',
    step1: {
      title: 'Accept or reject',
      accepted: 'Accepted',
      description:
        'Ihr Unternehmen, {companyName}, wurde vom Benutzer {invitationFromEmail} eingeladen, das Gerät {deviceName} ({controller}) zu warten. Um die Einladung anzunehmen, klicken Sie auf "Weiter" und füllen Sie die erforderlichen Daten aus.',
      rejectInvitation: 'Reject invitation',
    },
    step2: {
      title: 'Select customer',
      createNewCustomer: 'Create new customer',
      submitBtn: 'Abschließen',
    },
    rejectInvitationAction: {
      success: 'Invitation successfully rejected.',
      failure: 'Ablehnung der Einladung ist fehlgeschlagen.',
    },
    acceptInvitationAction: {
      success: 'Invitation successfully accepted.',
      failure: 'Annahme der Einladung ist fehlgeschlagen.',
    },
    addProductCataloguePermissionAction: {
      success:
        'Sie haben eine Berechtigung für den Produktkatalog erhalten, um diese Aktion durchführen zu können.',
      failure: 'Annahme der Einladung ist fehlgeschlagen.',
    },
  },
  eventCode: 'Event-Code',
  events: 'Events',
  detectionPoint: 'Erkennungspunkt',
  detectionPoints: 'Erkennungspunkte',
  coreCause: 'Problemquelle',
  coreCauses: 'Problemquellen',
  correctiveAction: 'Korrektive Maßnahme',
  correctiveActions: 'Korrektive Maßnahmen',
  nameTakenError: 'Name bereits benutzt.',
  adminEventCode: {
    severity: 'Schweregrad',
    description: 'Beschreibung',
    disabled: 'Deaktiviert',
    active: 'Aktiv',
    detectionPoint: 'Erkennungspunkt',
    detectionMechanism: 'Erkennungsmechanismus',
    terminationPoint: 'Beendigungspunkt',
    terminationMechanism: 'Beendigungsmechanismus',
    actionUponDetection: 'Aktion bei Erkennung',
    availableCauses: 'Verfügbare Problemquellen',
    assignedCauses: 'Zugewiesene Problemquellen',
  },
  adminEventCodes: {
    addEventCode: 'Event-Code hinzufügen',
    createEventCodeAction: {
      success: 'Event-Code erfolgreich erstellt.',
      failure: 'Erstellen dem Event-Code ist fehlgeschlagen.',
    },
  },
  adminDetectionPoints: {
    addDetectionPoint: 'Erkennungspunkt hinzufügen',
    updateDetectionPoint: 'Erkennungspunkt aktualisieren',
    createDetectionPointAction: {
      success: 'Erkennungspunkt erfolgreich erstellt.',
      failure: 'Erstellung eines Erkennungspunktes ist fehlgeschlagen.',
    },
    deleteConfirmationModal: {
      heading: 'Erkennungspunkt löschen',
      text: "Sind Sie sicher, dass Sie Erkennungspunkt '{detectionPoint} ' löschen möchten?",
    },
    deleteDetectionPointAction: {
      success: 'Erkennungspunkt erfolgreich gelöscht.',
      failure: 'Löschen des Erkennungspunktes ist fehlgeschlagen.',
    },
    updateDetectionPointAction: {
      success: 'Erkennungspunkt erfolgreich aktualisiert.',
      failure: 'Aktualisieren des Erkennungspunktes ist fehlgeschlagen.',
    },
  },
  adminCoreCauses: {
    addCoreCause: 'Problemquelle hinzufügen',
    createCoreCauseAction: {
      success: 'Problemquelle erfolgreich erstellt.',
      failure: 'Löschen der Problemquelle ist fehlgeschlagen.',
    },
  },
  adminCorrectiveActions: {
    addCorrectiveAction: 'Korrektive Maßnahme hinzufügen',
    createCorrectiveActionAction: {
      success: 'Korrektive Maßnahme erfolgreich erstellt.',
      failure: 'Erstellen der Korrektive-Maßnahmen ist fehlgeschlagen.',
    },
    deleteConfirmationModal: {
      heading: 'Korrektive Maßnahme löschen',
      text: "Bist du sicher, dass du die Korrektive Maßnahme '{correctiveAction} ' löschen möchtest?",
    },
    deleteCorrectiveActionAction: {
      success: 'Korrektive Maßnahme erfolgreich gelöscht.',
      failure: 'Löschen der Korrektive-Maßnahmen ist fehlgeschlagen.',
    },
    updateCorrectiveActionAction: {
      success: 'Korrektive Maßnahme. erfolgreich aktualisiert.',
      failure: 'Aktualisierung der Korrektive-Maßnahmen ist fehlgeschlagen.',
    },
  },
  eventCodeDetail: {
    title: 'Event-Code Detail',
    editAll: 'Alle bearbeiten',
    addCoreCause: 'Problemquelle hinzufügen',
    eventCodeNotFound: 'Event-Code nicht gefunden.',
    assignCoreCauseAction: {
      success: 'Problemquelle erfolgreich zugeordnet.',
      failure: 'Zuweisung der Problemquelle ist fehlgeschlagen.',
    },
    reorderCoreCausesAction: {
      success: 'Problemquellen erfolgreich neu geordnet.',
      failure: 'Neuordnung der Problemquellen ist fehlgeschlagen.',
    },
    deleteConfirmationModal: {
      heading: 'Event-Code löschen',
      text: "Sind Sie sicher, dass Sie den Event-Code '{eventCode}' löschen möchten?",
    },
    deleteEventCodeAction: {
      success: 'Event-Code erfolgreich gelöscht.',
      failure: 'Löschen des Event-Codes ist fehlgeschlagen.',
    },
    unassignCoreCauseConfirmationModal: {
      heading: 'Zuordnung der Problemquelle aufheben',
      text: "Sind Sie sicher, dass Sie die Zuordnung der Problemquelle '{coreCause} 'aufheben möchten?",
    },
    unassignCoreCauseAction: {
      success: 'Problemquelle Zuweisung erfolgreich aufgehoben.',
      failure: 'Aufhebung der Zuordnung der Problemquelle ist fehlgeschlagen.',
    },
    updateEventCodeModal: { heading: 'Ereignis-Code aktualisieren' },
    updateEventCodeAction: {
      success: 'Event-Code erfolgreich aktualisiert.',
      failure: 'Aktualisieren des Event-Codes ist fehlgeschlagen.',
    },
  },
  eventCorrectiveActionDetail: {
    title: 'Korrektive Maßnahmen',
    assignedToCoreCauses: 'Diesen Problemquellen zugewiesen',
    correctiveActionNotFound: 'Korrektive Maßnahmen nich gefunden.',
  },
  eventDetectionPointDetail: {
    title: 'Erkennungspunkt Details',
    assignedToEventCodes: 'Diesen Event-Codes zugewiesen',
    detectionPointNotFound: 'Erkennungspunkt nicht gefunden.',
  },
  eventCoreCauseDetail: {
    title: 'Ereignis-Code detail',
    addCorrectiveAction: 'Korrektive Maßnahme hinzufügen',
    coreCauseNotFound: 'Problemquelle nicht gefunden.',
    availableActions: 'Vefügbare Korrektive-Aktionen',
    assignedActions: 'Zugewiesene Aktionen',
    assignedToEventCodes: 'Diesen Event-Codes zugewiesen',
    assignCorrectiveActionAction: {
      success: 'Korrektive Maßnahme erfolgreich zugewiesen.',
      failure: 'Aufhebung der Zuweisung von Korrektive-Maßnahmen ist fehlgeschlagen.',
    },
    reorderCorrectiveActionsAction: {
      success: 'Corrective actions reordered successfully.',
      failure: 'Neuordnen der Korrektive-Maßnahmen ist fehlgeschlagen.',
    },
    deleteConfirmationModal: {
      heading: 'Problemquelle löschen',
      text: "Sind Sie sicher, dass Sie die Problemquelle '{coreCause} ' löschen möchten?",
    },
    deleteCoreCauseAction: {
      success: 'Problemquelle erfolgreich gelöscht.',
      failure: 'Löschen der Problemquelle ist fehlgeschlagen.',
    },
    unassignCorrectiveActionConfirmationModal: {
      heading: 'Aufhebung der Zuweisung von Korrektive-Maßnahmen',
      text: "Sind Sie sicher, dass Sie die Zuweisung von Korrekturmaßnahmen '{correctiveAction}' aufheben möchten?",
    },
    unassignCorrectiveActionAction: {
      success: 'Zuweisung von Korrektive Maßnahme erfolgreich entfernt.',
      failure: 'Aufhebung der Zuweisung von Abhilfemaßnahmen ist fehlgeschlagen.',
    },
    updateCoreCauseAction: {
      success: 'Problemquelle erfolgreich aktualisiert.',
      failure: 'Aktualisieren der Problemquelle ist fehlgeschlagen.',
    },
  },
  demoTokenTile: {
    token: 'Token',
    tokenValidUntil: 'bis',
    expired: 'Expired',
    neverExpires: 'Never expires',
  },
  demoTokenForm: {
    tokenExpiration: 'Token expiration',
    accessExpiration: 'Access expiration',
    neverExpires: 'Never expires',
    expirationDate: 'Expiration date',
  },
  updateDemoTokenModal: {
    headline: 'Edit demo token',
    updateDemoTokenAction: {
      success: 'Demo token updated successfully.',
      failure: 'Aktualisierung des Demo-Tokens ist fehlgeschlagen.',
    },
    deleteDemoTokenAction: {
      success: 'Demo token deleted successfully.',
      failure: 'Löschen des Demo-Tokens ist fehlgeschlagen.',
    },
  },
  demoTokenDeleteConfirmation: {
    headline: 'Demo-Token löschen',
    text: "Sind Sie sicher, dass Sie das Demo-Token '{tokenName}' löschen möchten?",
  },
  noDevicesText: 'Sie haben keine Geräte. Beginnen Sie damit, ein Gerät im Menü hinzuzufügen.',
  demoQrPrintTitle: 'Ihr Poolmatix Demo-Token',
  crowdin: {
    crowdin: 'Crowdin',
    uploadTranslations: 'Neue Quellen zu Crowdin hochladen',
    downloadTranslations: 'Neue Übersetzungen von Crowdin herunterladen',
    updateBase: 'Englische Übersetzungen als neue Basis setzen',
    confirmationDialog: 'Bestätigungsdialoge',
    uploadConfirmationText:
      'Sind Sie sicher, dass Sie aus dieser Umgebung neue englische Quelldateien in Crowdin hochladen möchten?',
    downloadConfirmationText:
      'Are you sure to download new translations from Crowdin to this environment? Please be sure that there are no recent changes in this environment that have not been uploaded to Crowdin. If there are changes that have not been uploaded to Crowdin yet, you should do that first. Otherwise, you may lose the new changes.',
    updateBaseConfirmationText:
      'Sind Sie sicher, dass Sie neue englische Übersetzungen in Crowdin als neue Basisdateien (Quelle) in Crowdin setzen möchten?',
    crowdinActionSuccess: 'Aktion erfolgreich beendet.',
    crowdinActionFailure: 'Aktion ist fehlgeschlagen.',
    crowdinActionPartialFailure: 'Aktion ist teilweise fehlgeschlagen. Siehe Ausgabe.',
    downloadOnlyDatabase:
      'Nur Zeichenketten in die Datenbank herunterladen, kein Pull-Request erstellen',
  },
  notificationDispatcher: {
    notificationDispatcher: 'Notifikationsverteiler',
    notificationFlow: 'Ablauf der Nachrichten',
    delayConfiguration: 'Benachrichtigungs-Timing',
    event: 'Event',
    notificationClass: 'Nachrichtigungsklasse',
    firstNotification: 'Erste Benachrichtigung',
    secondNotification: 'Zweite Benachrichtigung',
  },
  notificationClasses: {
    NO_NOTIFICATION: '<span style="color: #6B6B6B">KEINE BENACHRICHTUNG</span>',
    END_USER_ONLY: 'nur <span style="color: #1F97FF">ENDBENUTZER</span>',
    END_USER_THEN_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">ENDBENUTZER</span> dann <span style="color: #1867C1">PROFESSIONELLER BENUTZER</span>',
    END_USER_AND_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">ENDBENUTZER</span> und <span style="color: #1867C1">PROFESSIONELLER BENUTZER</span>',
    PROFESSIONAL_USER_THEN_END_USER:
      '<span style="color: #1867C1">PROFESSIONELLER BENUTZER</span> dann <span style="color: #1F97FF">ENDBENUTZER</span>',
    PROFESSIONAL_USER_ONLY: 'nur <span style="color: #1867C1">PROFESSIONELLER BENUTZER</span>',
  },
  defaultNotificationClass: 'Standard Benachrichtigungsklasse',
  updateNotificationClassDelays: {
    success: 'Benachrichtigung-Timing erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Benachrichtigungs-Timing ist fehlgeschlagen.',
  },
  serviceSequenceWizard: { deviceWentOffline: 'Gerät ist offline gegangen.' },
  abortServiceSequenceConfirmationModal: {
    heading: 'Abbruch der Service-Sequenz',
    text: 'Möchten Sie die laufende Service-Sequenz wirklich abbrechen?',
  },
  besgoValveBackwash: {
    title: 'Filterrückspülung - Besgo-Ventil',
    idle: 'Mit diesem Vorgang wird die Rückspülsequenz des Filters verlängert. Weiter?',
    running: 'Durchführen der Filterrückspül-Sequenz.',
    completed:
      'Filterrückspülvorgang-Sequenz abgeschlossen. (Verschwindet automatisch nach 2 Minuten).',
    failed: 'Filterrückspül-Sequenz is fehlgeschlagen.',
  },
  notResponding: 'Das Gerät antwortet nicht.',
  companyManagementMenu: {
    users: 'Benutzer',
    serviceCompanies: 'Service-Firmen',
    notifications: 'Benachrichtigungen',
    settings: 'Einstellungen',
  },
  companyDetail: {
    contactServicePartnerEmailTemplate: 'E-Mail-Muster für Kontakt zum Servicepartner',
    emailAddressesForContactEmails: 'Ziel-E-Mail-Adressen für "Service kontaktieren"',
    emailAddressesForEventNotifications: 'Ziel-E-Mail-Adressen für Event-Nachrichten',
    targetAddresses: 'Zieladresse',
    targetAddressesHint:
      'Wählen Sie aus den Optionen oder fügen Sie neue durch Drücken von Enter hinzu.',
    emailSubject: 'E-Mail-Betreff',
    emailBody: 'E-Mail-Körper',
    emailPreview: 'E-Mail-Vorschau',
    emailVariables: 'E-Mail-Felder',
    emailVariablesDescription:
      'Sie können diese Felder in Ihrer E-Mail-Vorlage verwenden. Die Felder werden mit echten Werten ausgefüllt.',
    invalidEmailAddresses: 'Geben Sie gültige E-Mail-Adressen ein.',
    restoreDefaults: 'Standard-E-Mail-Muster wiederherstellen',
    restoreDefaultsConfirmationModal: {
      heading: 'Standard-E-Mail-Muster wiederherstellen',
      text: 'Sind Sie sicher, dass Sie die Standard-E-Mail-Betreff- und Körpervorlage wiederherstellen möchten?',
    },
    updateCompanyData: {
      success: 'Die Unternehmensdaten wurden erfolgreich aktualisiert.',
      failure: 'Die Aktualisierung der Unternehmensdaten ist fehlgeschlagen.',
    },
    createNewEmail: 'Erstellen',
  },
  myAccount: 'Mein Konto',
  changePassword: 'Passwort ändern',
  currentPassword: 'Aktuelles Passwort',
  newPassword: 'Neues Passwort',
  newPasswordAgain: 'Neues Passwort wiederholen',
  passwordChangeAction: {
    success: 'Passwort erfolgreich aktualisiert.',
    failure: 'Aktualisierung des Passworts ist fehlgeschlagen.',
    notAuthorized: 'Falsches Passwort.',
  },
  acceptDeviceAccessRequest: {
    headline: 'Zugriffsanfrage für das Gerät akzeptieren',
    success:
      'Sie haben erfolgreich Benutzer {userEmail} Zugriff auf das Gerät "{deviceName}" gewährt.',
    invalidLink: 'Der Link ist ungültig.',
    requestNotFound: 'Link ist ungültig oder abgelaufen.',
    failure: 'Akzeptieren des Zugangs zu einer Sache ist fehlgeschlagen.',
  },
  descriptions: 'Beschreibungen',
  overview: 'Übersicht',
  companyDefaultLanguage: 'Unternehmenssprache',
  serviceSequenceSendCommandFailure: 'Fehler beim Senden des Befehls an das Gerät.',
  updateCompanyContactEmails: {
    success: 'Die Kontaktemails des Unternehmens wurden erfolgreich aktualisiert.',
    failure: 'Die Aktualisierung der Unternehmenskontakt-E-Mails ist fehlgeschlagen.',
  },
  updateCompanyContactEmailTemplate: {
    success: 'Die Vorlage für die Unternehmenskontakt-E-Mail wurde erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Firmenkontakt-E-Mail Vorlage ist fehlgeschlagen.',
  },
  contactEmailTemplate: 'Kontakt E-Mail-Vorlage',
  useDefaultConfiguration: 'Zu Einstellungen umshlalten',
  defaultConfiguration: 'Standard',
  customConfiguration: 'Individuelle',
  companySwitchToDefaultSettings: {
    success: 'Erfolgreich auf die Standardeinstellungen umschaltet.',
    failure: 'Umschalten auf die Standardeinstellungen ist fehlgeschlagen.',
  },
  companySwitchToCustomSettings: {
    success: 'Erfolgreich zu individuelle Einstellungen umschaltet.',
    failure: 'Wechsel zu den benutzerdefinierten Einstellungen ist fehlgeschlagen.',
  },
  defaultValue: 'Default-Wert',
  restoreDefaultNotificationDelaysConfirmationModal: {
    heading: 'Standard Benachrichtigungs-Timing wiederherstellen',
    text: 'Sind Sie sicher, dass Sie die Standardeinstellungen für die Benachrichtigungszeit wiederherstellen möchten? Alle aktuellen Einstellungen gehen dann verloren.',
  },
  restoreDefaultNotificationClassesConfirmationModal: {
    heading: 'Standard-Benachrichtigungsklassen wiederherstellen',
    text: 'Sind Sie sicher, dass Sie die Standardbenachrichtigungsklassen wiederherstellen möchten? Alle aktuellen benutzerdefinierten Benachrichtigungsklassen gehen verloren.',
  },
  updateNotificationClassForCompanyAndEventCode: {
    success: 'Benachrichtigungsklasse erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Benachrichtigungsklasse ist fehlgeschlagen.',
  },
  timezone: 'Zeitzone',
  changeOperatingModeModal: {
    heading: 'Betriebsmodus aktualisieren',
    deviceIsOffline: 'Gerät ist offline, kann Betriebsmodus nicht aktualisieren.',
    serviceSequenceInProgress:
      'Operating mode cannot be updated. A service sequence is in progress.',
  },
  changeOperatingModeConfirmationModal: {
    heading: 'Betriebsmodus',
    text: 'Sind Sie sicher, den Betriebsmodus zu aktualisieren?',
  },
  updateOperatingModeAction: {
    success: 'Betriebsmodus erfolgreich aktualisiert.',
    failure: 'Aktualisieren des Betriebsmodus ist fehlgeschlagen.',
  },
  predefinedSchedule: 'System-Zeitplan',
  mySchedule: 'Mein Zeitplan',
  editingGlobalScheduleWarning:
    'Durch Bearbeiten eines System-Zeitplans wird Ihre neue Kopie des Zeitplans erstellt.',
  newTimeSchedule: {
    daily: 'Neuer Tages-Zeitplan',
    weekly: 'Neuer Wochen-Zeitplan',
  },
  timeScheduleName: 'Zeitplan Name',
  timeScheduleBaseTemplate: 'Standard-Zeitplan',
  editDailyTimeScheduleName: 'Tages-Zeitplan-Name bearbeiten',
  editWeeklyTimeScheduleName: 'Wochen-Zeitplan-Name bearbeiten',
  enterDailyTimeScheduleName: 'Name des Tages-Zeitplans eingeben',
  enterWeeklyTimeScheduleName: 'Name des Wochen-Zeitplans eingeben',
  weeklyTimeScheduleDeleteConfirmationHeading: 'Wochenplan löschen',
  weeklyTimeScheduleDeleteConfirmationText:
    "Sind Sie sicher, dass Sie den Wochenplan '{scheduleName}' löschen möchten?",
  newWeeklyTimeScheduleDeleteConfirmationHeading: 'Neuen Wochenplan löschen',
  newWeeklyTimeScheduleDeleteConfirmationText:
    'Sind Sie sicher, dass Sie diesen neuen Wochenplan löschen möchten?',
  dailyTimScheduleDeleteConfirmationHeading: 'Tagesplan löschen',
  dailyTimScheduleDeleteConfirmationText:
    "Sind Sie sicher, dass Sie den Tagesplan '{scheduleName}' löschen möchten?",
  newDailyTimScheduleDeleteConfirmationHeading: 'Neuen Tagesplan löschen',
  newDailyTimScheduleDeleteConfirmationText:
    'Sind Sie sicher, dass Sie diesen neuen Tagesplan löschen möchten?',
  addDailySchedule: 'Tages-Zeitplan hinzufügen',
  weeklyTimeSchedules: 'Wochen-Zeitpläne',
  dailyTimeSchedules: 'Tages-Zeitpläne',
  weeklyTimeSchedule: 'Wochen-Zeitplan',
  dailyTimeSchedule: 'Tages-Zeitplan',
  disabledDeleteTooltipForScheduleGlobal: 'Kann nicht einen System-Zeitplan löschen.',
  disabledDeleteTooltipForDailyScheduleUsed:
    'Kann nicht einen verwendeten Tages-zeitplan löschen. Dieser Tages-Zeitplan wird in einem Wochen-Zeitplan verwendet. Bitte entfernen Sie zuerst den Tages-Zeitplan aus allen Wochen-Zeitplänen.',
  disabledDeleteTooltipForWeeklyActive:
    'Kann nicht einen aktiven Wochen-Zeitplan löschen. Dieser Wochen-Zeitplan ist derzeit aktiv. Bitte aktivieren Sie zunächst einen anderen Wochen-Zeitplan.',
  disabledDeleteTooltipForWeeklyLast:
    'Kann nicht den letzten verbleibenden Wochenzeitplan löschen. Es muss mindestens ein Wochen-Zeitplan vorhanden sein. Bitte erstellen Sie zunächst einen neuen Wochen-Zeitplan.',
  disabledDeleteTooltipForWeeklyUsed:
    'Es ist nicht möglich, einen verwendeten Wochenplan zu löschen. Dieser Wochenplan wird von einigen Geräten verwendet. Bitte stellen Sie sicher, dass dieser Wochenplan nicht von irgendwelchen Geräten verwendet wird, bevor Sie ihn löschen.',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  createServiceHistoryEntry: {
    success: 'Datensatz erfolgreich erstellt.',
    failure: 'Erstellen dem Datensatz ist fehlgeschlagen.',
  },
  customNotificationClasses: 'Benutzerdefinierte Nachrichtigungsklassen',
  noCustomClasses: 'Keine benutzerdefinierten Nachrichtigungsklassen',
  createCustomNotificationClass: 'Benutzerdefinierte Nachrichtigungsklasse erstellen',
  createCustomNotificationClassAction: {
    success: 'Benutzerdefinierte Nachrichtigungsklasse erfolgreich erstellt.',
    failure: 'Erstellung einer benutzerdefinierten Nachrichtigungsklasse ist fehlgeschlagen.',
  },
  deleteCustomNotificationClassAction: {
    success: 'Benutzerdefinierte Nachrichtigungsklasse erfolgreich gelöscht.',
    failure: 'Löschen einer benutzerdefinierten Nachrichtigungsklasse ist fehlgeschlagen.',
  },
  canNotDeleteUsedCustomNotificationClassTooltip:
    'Die benutzerdefinierte Nachrichtigungsklasse kann nicht gelöscht werden.',
  deleteCustomNotificationClassConfirmationModal: {
    heading: 'Benutzerdefinierte Nachrichtigungsklasse löschen',
    text: "Sind Sie sicher, dass Sie die benutzerdefinierte Nachrichtigungsklasse '{className}' löschen möchten?",
  },
  newErrorNotification: 'Neues Systemereignis',
  errorMessageClosed: 'Systemereignis geschlossen',
  loadMore: 'Mehr laden',
  backToTop: 'Zurück zum Anfang',
  backToDashboard: 'Zurück zum Dashboard',
  backToProductsList: 'Zurück zu Basisprodukten',
  serviceHistoryEntryTypes: {
    USER_NOTE: 'Benutzernachrichten',
    EVENT: 'Systemereignisse',
    OPERATION_COUNTER_CYCLE: 'Betriebszyklen',
    SERVICE_PARTNER_CONTACT: 'Nachrichten an Servicepartner',
  },
  filter: 'Filter',
  discardChanges: 'Änderungen verwerfen',
  continueEditing: 'Weiter bearbeiten',
  wifiCredentials: 'WLAN-Zugangsdaten',
  wifiCredentialsNotFound:
    'WLAN-Zugangsdaten konnten nicht geladen werden. Bitte kontaktieren Sie den Support.',
  show: 'Anzeigen',
  ssid: 'SSID',
  wifiQrCode: 'Scannen Sie den QR-Code, um sich von Ihrem Handy aus zu verbinden.',
  iXsolveNotifications: 'Neues Systemereignis',
  user: 'Benutzer',
  chargersApiKey: 'Ladegerät API-Schlüssel',
  noChargerApiKey: 'Sie haben derzeit keinen API-Schlüssel.',
  hasChargerApiKey:
    'Ihr API-Schlüssel ist aktiv. Wenn Sie den API-Schlüssel verloren haben, erstellen Sie bitte einen neuen.',
  deleteApiKey: 'API-Schlüssel löschen',
  copyApiKeyWarning:
    'Bitte speichern Sie diesen neuen persönlichen API-Schlüssel. Sie werden ihn nicht mehr anzeigen können.',
  generateNewApiKey: 'Neuen API-Schlüssel generieren',
  confirmGenerateNew: 'Alten API-Schlüssel löschen und einen neuen generieren',
  generateNewApiKeyConfirmationModal: {
    heading: 'Neuen API-Schlüssel für das Ladegerät generieren',
    text: 'Sind Sie sicher, dass Sie einen neuen API-Schlüssel generieren möchten? Ihr aktueller API-Schlüssel wird gelöscht.',
  },
  deleteApiKeyConfirmationModal: {
    heading: 'Ladegerät API-Schlüssel löschen',
    text: 'Sind Sie sicher, dass Sie Ihren Ladegerät API-Schlüssel löschen möchten?',
  },
  generateNewApiKeyAction: {
    success: 'API-Schlüssel erfolgreich generiert.',
    failure: 'Erzeugen eines neuen API-Schlüssels für das Ladegerät ist fehlgeschlagen.',
  },
  deleteApiKeyAction: {
    success: 'API-Schlüssel erfolgreich generiert.',
    failure: 'Löschen des API-Schlüssels des Ladegeräts ist fehlgeschlagen.',
  },
  energy: 'Energieverbrauch',
  dateRangeChoice: {
    today: 'Heute',
    yesterday: 'Gestern',
    thisWeek: 'Diese Woche',
    lastWeek: 'Letzte Woche',
    thisMonth: 'Dieser Monat',
    lastMonth: 'Letzter Monat',
    last30days: 'Letzten 30 Tage',
    last90days: 'Letzten 90 Tage',
    thisYear: 'Dieses Jahr',
    lastYear: 'Letztes Jahr',
  },
  datesSelected: 'Ausgewählt',
  energyChartsNoData: 'Unzureichende Daten für den gewählten Zeitraum.',
  hourCounter: 'Stundenanzahl',
  currentHourCounter: 'Aktuelle Stundenanzahl',
  newHourCounter: 'Neue Stundenanzahl',
  startCounter: 'Startanzahl',
  currentStartCounter: 'Aktuelle Startanzahl',
  newStartCounter: 'Neue Startanzahl',
  cycleStartDate: 'Aktuellen-Zyklusstart',
  newCycleStart: 'Neuer-Zyklusstart',
  newOperatingCycle: 'Neuer Betriebszyklus',
  startNewCycle: 'Neuen Zyklus starten',
  equipmentId: 'Aktuelle Geräte-ID',
  newEquipmentId: 'Neue Geräte-ID',
  equipmentType: 'Gerätetyp',
  operationCounterNotePlaceholder: 'Benutzernotiz',
  operationCounterCycleCreateAction: {
    success: 'Neuer Betriebszyklus erstellt.',
    failure: 'Erstellung eines neuen Betriebszyklus ist fehlgeschlagen.',
  },
  noOperationCounters: 'Kein Betriebszähler verfügbar.',
  serviceOrderNumber: 'Auftragnummer',
  assignee: 'Zugewiesen zu',
  createdBy: 'Erstellt von',
  createdAt: 'Datum / Zeit',
  serviceOrderStatuses: {
    OPEN: 'Erstellt',
    IN_PROGRESS: 'In Bearbeitung',
    CLOSED: 'Geschlossener',
  },
  serviceOrderText: 'Text',
  createServiceOrder: 'Serviceauftrag erstellen',
  createServiceOrderAction: {
    success: 'Serviceauftrag erfolgreich erstellt.',
    failure: 'Erstellen dem Serviceauftrag ist fehlgeschlagen.',
  },
  updateServiceOrderAction: {
    success: 'Serviceauftrag erfolgreich aktualisiert.',
    failure: 'Aktualisieren dem Serviceauftrag ist fehlgeschlagen.',
  },
  deleteServiceOrderAction: {
    success: 'Serviceauftrag erfolgreich gelöscht.',
    failure: 'Löschen dem Serviceauftrag ist fehlgeschlagen.',
  },
  serviceOrder: 'Serviceauftrag',
  thingData: 'Gerätedaten',
  serviceOrderDeleteConfirmation: {
    heading: 'Serviceauftrag löschen',
    text: "Sind Sie sicher, dass Sie den Serviceauftrag '{serviceOrderNumber}' löschen möchten?",
  },
  emailNotifications: 'Email-Benachrichtigungen',
  emailNotificationsAddFirst:
    'Geben Sie mindestens eine E-Mail-Adresse ein, um Benachrichtigungen zu aktivieren.',
  smsNotifications: 'SMS-Benachrichtigungen',
  smsNotificationsAddFirst:
    'Geben Sie mindestens eine E-Mail-Adresse ein, um Benachrichtigungen zu aktivieren.',
  pushNotifications: 'Push Benachrichtigungen',
  pushNotificationsText:
    'Push-Benachrichtigungen werden an alle Geräte mit der iXmanager-App gesendet, die dieses Gerät verwenden.',
  deviceSetEventNotificationsAction: {
    success: 'Benachrichtigungseinstellungen erfolgreich aktualisiert.',
    failure: 'Die Aktualisierung der Benachrichtigungseinstellungen ist fehlgeschlagen.',
  },
  energyReports: 'Energieberichte',
  configurationJobInProgress: 'Aktualisierung der Einstellungen in Arbeit.',
  dataPropagationFailed: 'Aktualisierung der Einstellungen fehlgeschlagen.',
  configurationChanged: 'Einstellungen wurden geändert, aber noch nicht an das Gerät gesendet.',
  confirmDiscardChangesModal: {
    heading: 'Änderungen verwerfen',
    text: 'Sind Sie sicher, dass Sie die nicht gesendeten Änderungen verwerfen möchten, die nicht an das Gerät gesendet wurden? Dadurch werden auch alle ausstehenden Einstellungsaktualisierungen abgebrochen.',
  },
  deviceCancelConfigurationAction: {
    success: 'Einstellungen erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Einstellungen fehlgeschlagen.',
    inProgressError:
      'Die Aktualisierung der Einstellungen kann nicht mehr abgebrochen werden. Bitte warten Sie, bis sie abgeschlossen ist.',
  },
  mode: 'Modus',
  duplicateSchedule: 'Duplizieren',
  duplicateScheduleTooltip: 'Erstelle einen neuen Zeitplan aus diesem Zeitplan',
  repeat: 'Wiederholen',
  daily: 'Täglich',
  activate: 'Aktivieren',
  dailyScheduleNotUsedError:
    'Ein Tagesplan muss mindestens einmal in einem Wochenplan verwendet werden.',
  everyDayHasToBeUsedError:
    'Bitte erstellen Sie einen Tagesplan für jeden Tag der Woche. Fehlende Tage:',
  weeklyScheduleEmptyError: 'Ein Wochenplan muss 1 bis 7 Tagespläne enthalten.',
  scheduleInvalidNameError: 'Bitte geben Sie einen gültigen Namen für den Zeitplan ein.',
  noChangeError: 'Es wurden keine Änderungen vorgenommen.',
  newSchedule: 'Neuer Zeitplan',
  nothingToSeeHereYet: 'Keine Wochenpläne definiert.',
  addFirstDailyScheduleMessage: 'Bitte erstellen Sie Ihren ersten Tagesplan.',
  saveWithWipModal: {
    heading: 'Ungespeicherter Zeitintervall',
    text: 'Einige Intervalle sind nicht gespeichert worden. Bitte gehen Sie zurück und schließen Sie Ihre Änderungen ab oder speichern Sie den Zeitplan mit oder ohne nicht gespeicherte Intervalle.',
    saveWithWip: 'Speichern mit ungespeicherten Intervallen',
    saveWithoutWip: 'Speichern und dabei ungespeicherte Intervalle auslassen',
  },
  editDailyTimeSchedule: 'Tagesplan bearbeiten',
  newWeeklySchedule: 'Wochenplan hinzufügen',
  newDailySchedule: 'Neuer Tagesplan',
  dailySchedules: 'Tagespläne',
  today: 'Heute',
  activeWeeklySchedule: 'Aktiver Wochenplan',
  saveDailyConfirmationModal: {
    heading: 'Änderungen speichern',
    text: 'Tagesplan <b>{scheduleName}</b>, der in diesen Wochenplänen verwendet wird. Änderungen am Tagesplan wirken sich auf alle diese Wochenpläne aus.',
  },
  duplicateSchedulePostfix: 'Kopie',
  new: 'neu',
  selectAll: 'Alles auswählen',
  systemTimeScheduleEditValidationModal: {
    heading: 'Der Systemzeitplan kann nicht bearbeitet werden.',
    info: 'Sie können Ihre eigene Kopie des Zeitplans erstellen und bearbeiten.',
    duplicateAction: 'Eine Kopie erstellen',
  },
  addProduct: {
    selectDevice: 'Gerät auswählen',
    selectProduct: 'Produkt auswählen',
    setTemplate: 'Vorlage auswählen',
  },
  cancelSequence: 'Sequenz abbrechen',
  saveAndActivate: 'Speichern und aktivieren',
  xeeloIntegration: 'Xeelo-Integration',
  addXeeloIntegration: 'Xeelo-Integration hinzufügen',
  addXeeloIntegrationConfirmation: {
    heading: 'Xeelo-Integration hinzufügen',
    text: 'Sind Sie sicher, dass Sie die Xeelo-Integration für Unternehmen {companyName} hinzufügen möchten?',
  },
  addXeeloIntegrationAction: {
    success: 'Xeelo-Integration erfolgreich hinzugefügt.',
    failure: 'Hinzufügen der Xeelo-Integration ist fehlgeschlagen.',
  },
  paymentType: {
    ONE_OFF: 'Einmalig',
    SUBSCRIPTION: 'Abonnement',
  },
  serviceSequence: 'Service-Sequenz',
  chargingStart: 'Laden Startzeit',
  duration: 'Dauer',
  totalEnergy: 'Energie insgesamt',
  chargingHistoryNoData: 'Keine Daten verfügbar.',
  export: 'Daten exportieren',
  exportChargingHistory: 'Exportieren der Ladehistorie',
  dateFrom: 'Von',
  dateTo: 'Bis',
  chargingHistoryExportFailure: 'Export der Ladehistorie ist fehlgeschlagen.',
  invalidDate: 'Ungültiges Datum.',
  chargingHistoryDateFromHint:
    'Leer lassen, um mit dem ältesten verfügbaren Datensatz zu beginnen.',
  chargingHistoryDateToHint: 'Defaultdatum ist heute.',
  errorInTimeScheduleConfiguration:
    'Nicht unterstützte Zeitplankonfiguration. Bitte kontaktieren Sie Ihren Händler.',
  errorInTimeScheduleConfigUpdate:
    'Die Änderung des Systemzeitplans kann nicht abgeschlossen werden. Die folgenden Geräte haben Konfigurationen, die mit dem neuen Zeitplan nicht kompatibel sind: {deviceList}.',
  eligibilityControl: {
    available: 'Verfügbar',
    notAvailable: 'Nicht verfügbar',
    optional: 'Optional',
  },
  feature: 'Feature',
  eligibilityFilter: {
    all: 'Alle',
    oneOff: 'Einmalig',
    subscription: 'Abonnement-basiert',
    serviceSequences: 'Service-Sequenzen',
    premium: 'Premium',
  },
  creatBaseProduct: 'Neues Basisprodukt erstellen',
  baseProduct: 'Basisprodukt',
  selectProductTemplate: '-',
  noTemplateYet: 'Kein Basisprodukt verfügbar für diese Gerätetypfamilie.',
  createProduct: 'Neues Basisprodukt hinzufügen',
  availability: 'Verfügbarkeit',
  newProductCreate: {
    success: 'Produkteinstellungen erfolgreich gespeichert.',
    failure: 'Das Speichern der Produkteinstellungen ist fehlgeschlagen.',
  },
  newProductUpdate: {
    success: 'Produkteinstellungen erfolgreich aktualisiert.',
    failure: 'Aktualisierung der Produkteinstellungen ist fehlgeschlagen.',
  },
  addBaseProduct: 'Neues Basisprodukt',
  editBaseProduct: 'Basisprodukt bearbeiten',
  productSettings: 'Produkt Einstellungen',
  availabilityBaseProduct: 'Verfügbarkeit im Basisprodukt',
  defaultAvailability: 'Standard-Verfügbarkeit',
  selectBaseProduct: 'Basisprodukt auswählen',
  premium: 'Premium',
  newProductCatalogueMenu: {
    thingsPage: 'Geräte',
    baseProductsPage: 'Basisprodukte',
  },
  changeProductModal: {
    title: 'Basisprodukt ändern',
    description: 'Das Ändern des Basisprodukts wird alle Geräteeinstellungen löschen. Weiter?',
  },
  thingTypeFamily: 'Gerätetyp-Familie',
  premiumFeatureModalTitle: 'Aktivierung der Premium-Funktion',
  premiumFeatureModalDesc:
    'Sie aktivieren eine Premium-Funktion. Es können zusätzliche Gebühren anfallen. Weiter?',
  eligibilityControlTooltip:
    'Diese Funktion ist immer verfügbar. Die Standardverfügbarkeit kann nicht geändert werden.',
  provisionType: 'Bereitstellungsart',
  updateThingType: 'Gerätetyp aktualisieren',
  thingTypeRelationUpdateSuccess: 'Beziehungsaktualisierung erfolgreich.',
  thingTypeRelationUpdateFail: 'Beziehungsaktualisierung fehlgeschlagen.',
  tools: 'Tools',
  deleteWeeklyScheduleInfo: 'Wöchenzeitplan wurde gelöscht.',
  deleteDailyScheduleInfo: 'Tagenzeitplan wurde gelöscht.',
  duplicateWeeklyScheduleInfo: 'Wöchenzeitplan wurde dupliziert.',
  duplicateDailyScheduleInfo: 'Tagenzeitplan wurde dupliziert.',
  scheduleDuplicateFailed: 'Duplizieren des Zeitplans fehlgeschlagen.',
  messageToServicePartner: 'Nachricht an Servicepartner',
  newMessage: 'Neue Nachricht',
  emailSentTo: 'E-Mail gesendet an',
  queryFailed: 'Es ist ein Fehler beim Laden der Daten aufgetreten.',
  retry: 'Wiederholen',
  newUserMessage: 'Neue Benutzernachricht',
  userMessage: 'Benutzernachricht',
  operatingCycle: 'Betriebszyklus',
  systemEvent: 'Systemereignis',
  dateFormats: {
    minutes: 'MM',
    seconds: 'SS',
    hours: 'ST',
  },
}
