<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" width="unset">
    <v-card>
      <div class="pa-6">
        <h2 class="mb-6">
          <!-- {{ heading }} -->
          {{ $t('device.saveWithoutSending') }}
        </h2>

        <div class="mt-8 text-right">
          <v-btn @click="onReject()" depressed>
            {{ $t('global.back') }}
          </v-btn>

          <v-btn @click="onConfirm()" color="primary" class="ml-3" depressed>
            <!-- {{ submitBtnText || $t('global.submit') }} -->

            {{ $t('global.confirm') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SaveChangesWithoutSavingModal',

  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    onConfirm: Function,
    onReject: Function,
  },
  data() {
    return {
      value: this.originalValue,
    }
  },

  watch: {
    isOpen() {
      this.value = this.originalValue
    },
  },
}
</script>
<style lang="less" scoped></style>
