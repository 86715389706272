import { render, staticRenderFns } from "./SaveChangesWithoutSendingModal.vue?vue&type=template&id=59baa92c&scoped=true"
import script from "./SaveChangesWithoutSendingModal.vue?vue&type=script&lang=js"
export * from "./SaveChangesWithoutSendingModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59baa92c",
  null
  
)

export default component.exports