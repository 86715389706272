export default {
  menu: {
    pools: 'Bazény',
    sewagePumps: 'Tlakové kanalizační jednotky',
    chargePoint: 'Nabíjecí zařízení',
    companyManagement: 'Firma',
    templates: 'Šablony',
    admin: 'Admin',
    productCatalogue: 'Produkty',
    inviteCompany: 'Pozvat firmu',
    logout: 'Odhlášení',
  },
  deviceTypes: {
    POOL: 'Bazén',
    SEWAGE_PUMP: 'Tlaková kanalizační jednotka',
    CHARGE_POINT: 'Nabíjecí zařízení',
  },
  device: 'Zařízení',
  devices: 'Zařízení',
  basicInformation: 'Základní údaje',
  operatingValues: 'Provozní údaje',
  controls: 'Ovládání',
  language: 'Jazyk',
  lang: {
    cs: 'Čeština',
    en: 'English',
    de: 'Deutsch',
    pl: 'Polski',
  },
  daysOfWeek: {
    MONDAY: 'Pondělí',
    TUESDAY: 'Úterý',
    WEDNESDAY: 'Středa',
    THURSDAY: 'Čtvrtek',
    FRIDAY: 'Pátek',
    SATURDAY: 'Sobota',
    SUNDAY: 'Neděle',
  },
  daysOfWeekShort: {
    MONDAY: 'Po',
    TUESDAY: 'Út',
    WEDNESDAY: 'St',
    THURSDAY: 'Čt',
    FRIDAY: 'Pá',
    SATURDAY: 'So',
    SUNDAY: 'Ne',
  },
  reset: 'Resetovat',
  save: 'Uložit',
  delete: 'Odstranit',
  cancel: 'Zrušit',
  discard: 'Zrušit',
  back: 'Zpět',
  continue: 'Pokračovat',
  confirm: 'Potvrdit',
  enter: 'Vstoupit',
  accept: 'Přijmout',
  close: 'Zavřít',
  submit: 'Odeslat',
  edit: 'Upravit',
  unassign: 'Zrušit přiřazení',
  add: 'Přidat',
  remove: 'Odstranit',
  clear: 'Smazat',
  manage: 'Spravovat',
  view: 'Zobrazit',
  abort: 'Zrušit',
  ok: 'OK',
  hide: 'Skrýt',
  id: 'ID',
  email: 'Email',
  name: 'Jméno',
  title: 'Název',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  contactName: 'Kontaktní osoba',
  contactEmail: 'Kontakní email',
  contactPhone: 'Kontaktní telefon',
  companyName: 'Název firmy',
  phoneNumber: 'Telefon',
  description: 'Popis',
  enabled: 'Aktivní',
  deactivated: 'Deaktivovaný',
  contactInfo: 'Kontaktní informace',
  address: 'Adresa',
  billingAddress: 'Fakturační adresa',
  note: 'Poznámka',
  set: 'Nastavit',
  location: 'Poloha',
  map: 'Mapa',
  settings: 'Konfigurace',
  template: 'Šablona',
  deviceName: 'Název zařízení',
  status: 'Status',
  state: 'Stav',
  defaultTimeSchedule: 'Výchozí časový rozvrh',
  myDevices: 'Moje zařízení',
  product: 'Produkt',
  endUserProduct: 'Produkt koncového uživatele',
  professionalUserProduct: 'Produkt profesionálního uživatele',
  customer: 'Zákazník',
  company: 'Firma',
  type: 'Typ',
  companyAccess: 'Firemní přístup',
  myAccess: 'Můj přístup',
  password: {
    notSame: 'Hesla se neshodují.',
    notValid:
      'Heslo musí mít alespoň 8 znaků, 1 číslo, velké a malé znaky a alespoň jeden speciální znak.',
  },
  countryCodes: {
    al: 'Albánie',
    ad: 'Andorra',
    am: 'Arménie',
    at: 'Rakousko',
    az: 'Ázerbájdžán',
    by: 'Bělorusko',
    be: 'Belgie',
    ba: 'Bosna a Hercegovina',
    bg: 'Bulharsko',
    hr: 'Chorvatsko',
    cy: 'Kypr',
    cz: 'Česko',
    dk: 'Dánsko',
    ee: 'Estonsko',
    fi: 'Finsko',
    fr: 'Francie',
    ge: 'Gruzie',
    de: 'Německo',
    gr: 'Řecko',
    hu: 'Maďarsko',
    is: 'Island',
    ie: 'Irsko',
    it: 'Itálie',
    kz: 'Kazachstán',
    lv: 'Lotyšsko',
    li: 'Lichtenštejnsko',
    lt: 'Litva',
    lu: 'Lucembursko',
    mt: 'Malta',
    md: 'Moldavsko',
    mc: 'Monako',
    me: 'Černá Hora',
    nl: 'Nizozemsko',
    mk: 'Severní Makedonie',
    no: 'Norsko',
    pl: 'Polsko',
    pt: 'Portugalsko',
    ro: 'Rumunsko',
    ru: 'Rusko',
    sm: 'San Marino',
    rs: 'Srbsko',
    sk: 'Slovensko',
    si: 'Slovinsko',
    es: 'Španělsko',
    se: 'Švédsko',
    ch: 'Švýcarsko',
    tr: 'Turecko',
    ua: 'Ukrajina',
    gb: 'Spojené království',
    va: 'Vatikán',
  },
  addressItems: {
    route: 'Adresa',
    city: 'Město',
    postalCode: 'PSČ',
    country: 'Země',
    lat: 'Zeměpisná šířka',
    lng: 'Zeměpisná délka',
  },
  addressSpecification: 'Podrobnosti adresy',
  addressHint: 'Vyberte adresu nebo vyplňte ručně.',
  yes: 'Ano',
  no: 'Ne',
  errors: {
    emailAlreadyExists:
      'Účet s touto emailovou adresou již existuje. Přihlaste se prosím způsobem, který jste použili pro registraci.',
    invalidInvitation: 'Pozvánka vypršela nebo je neplatná.',
    userDeactivated:
      'Účet deaktivován. Chcete-li znovu aktivovat svůj účet, kontaktujte technickou podporu.',
    somethingWentWrong:
      'Někde nastala chyba. Prosím zkuste to znovu nebo kontaktujte technickou podporu.',
  },
  newDeviceResetConfirmationModal: {
    heading: 'Resetovat formulář',
    text: 'Opravdu chcete obnovit výchozí hodnoty?',
  },
  companyInvite: {
    inviteCompany: 'Pozvat firmu',
    description: 'Zadejte email administrátora zvané firmy.',
    submit: 'Odeslat pozvánku',
    invitationSuccess: 'Pozvánka úspěšně odeslána.',
    invitationError: 'Odesílání pozvánky se nezdařilo.',
    sentInvitations: 'Odeslané pozvánky',
    resendInvitation: 'Odeslat znovu',
  },
  createCompanyModal: {
    headline: 'Vytvořit novou firmu',
    description: 'Zadejte název nové firmy.',
    companyName: 'Název firmy',
    successMessage: 'Firma úspěšně vytvořena.',
    errorMessage: 'Vytvoření firmy se nezdařilo.',
    companyNameTakenError: 'Název společnosti je obsazen. Zvolte prosím jiný název.',
  },
  formValidation: {
    required: 'Toto pole je povinné.',
    notPhoneNumber: 'Zadejte platné telefonní číslo včetně kódu země.',
    notEmail: 'Zadejte platnou e-mailovou adresu.',
    notSameAsPassword: 'Hesla se neshodují.',
    tooLong: 'Překročena maximální délka.',
    max: 'Hodnota musí být menší nebo rovna {max}',
    min: 'Hodnota musí být větší nebo rovna {min}',
    integer: 'Hodnota musí být celé číslo.',
  },
  pageNotFound: 'Stránka nenalezena.',
  insufficientPermissions: 'Nedostatečná oprávnění pro přístup ke stránce.',
  unsavedChanges: ' Máte neuložené změny. Opravdu chcete odejít a zahodit změny?',
  on: 'Zapnuto',
  off: 'Vypnuto',
  copiedToClipboard: 'Zkopírováno do schránky.',
  copyToClipboardFailed: 'Kopírování do schránky se nezdařilo.',
  all: 'Vše',
  doFilter: 'Filtrovat',
  schedule: 'Časový rozvrh',
  schedules: 'Časové rozvrhy',
  hrs: 'hod.',
  dataUpdateSuccess: 'Údaje úspěšně aktualizovány.',
  dataUpdateFailure: 'Aktualizace dat se nezdařila.',
  invalidInvitationModal: {
    header: 'Neplatná pozvánka.',
    text: 'Pozvánka vypršela nebo stala neplatná.',
  },
  acceptCompanyToCompanyInvitationModal: {
    header: 'Pozvánka do firmy',
    text: 'Vaše firma, {childCompanyName}, byla pozvána do firmy {parentCompanyName}. Přijetím této pozvánky se firma {childCompanyName} stane servisním partnerem firmy {parentCompanyName}. Chcete přijmout tuto pozvánku?',
  },
  acceptCompanyToCompanyInvitation: {
    success: 'Pozvánka úspěšně přijata.',
    failure: 'Přijímání pozvánky se nezdařilo.',
  },
  templateManagement: 'Správa šablon',
  addTemplate: 'Přidat šablonu',
  emptyTemplate: 'Prázdná šablona',
  dataTable: {
    itemsPerPageText: 'Řádků na stránku:',
    allItemsText: 'Vše',
    of: 'z',
  },
  anonymous: 'Anonym',
  adminConsole: 'Administrátorská konzole',
  adminConsoleMenu: {
    users: 'Uživatelé',
    devices: 'Zařízení',
    productCatalogue: 'Produktový katalog',
    companies: 'Firmy',
    serviceHints: 'Servisní nápověda',
    notificationDispatcher: 'Hlášení',
    thingTypes: 'Typy zařízení',
  },
  productCatalogueMenu: {
    devices: 'Zařízení',
    customers: 'Zákazníci',
    endUserProducts: 'Produkty koncového uživatele',
    professionalUserProducts: 'Produkty profesionálního uživatele',
  },
  addCustomer: 'Přidat zákazníka',
  addEndUserProduct: 'Přidat produkt koncového uživatele',
  addProfessionalUserProduct: 'Přidat produkt profesionálního uživatele',
  customerCreate: {
    success: 'Zákazník byl úspěšně vytvořen.',
    failure: 'Vytvoření zákazníka se nezdařilo.',
  },
  customerUpdate: {
    success: 'Zákaznické údaje úspěšně aktualizovány.',
    failure: 'Úprava zákaznických údajů se nezdařila.',
  },
  customerDetail: 'Detail zákazníka',
  customerNotFound: 'Zákazník nenalezen.',
  editCustomerData: 'Úprava zákaznických údajů',
  deviceType: 'Typ zařízení',
  eligibilities: 'Oprávnění',
  productCreate: {
    success: 'Produkt úspěšně vytvořen.',
    failure: 'Vytvoření produktu se nezdařilo.',
  },
  days: 'dnů',
  hours: 'hodin',
  minutes: 'minut',
  thingType: 'Typ zařízení',
  selectThingTypesHint: 'Vyberte jeden nebo více typů zařízení.',
  endUserProductDetail: 'Detail produktu koncového uživatele',
  professionalUserProductDetail: 'Detail produktu profesionálního uživatele',
  productNotFound: 'Produkt nenalezen.',
  defaultProductForType: 'Základní produkt pro {thingType}',
  defaultProduct: 'Výchozí produkt',
  systemProduct: 'Systémový produkt',
  editProduct: 'Úprava produktu',
  productUpdate: {
    success: 'Produkt úspěšně aktualizován.',
    failure: 'Vytvoření produktu se nezdařilo.',
  },
  addDevice: 'Přidat zařízení',
  assignDeviceToCustomer: 'Přiřadit zařízení zákazníkovi',
  deviceAssignCustomerAndProduct: {
    success: 'Zařízení úspěšně přiřazeno zákazníkovi.',
    failure: 'Přiřazení zařízení se nezdařilo.',
  },
  deviceRemoveCustomerAndProduct: {
    success: 'Zařízení úspěšně odebráno zákazníkovi.',
    failure: 'Odebrání zařízení se nezdařilo.',
  },
  deviceUnassignConfirmation: {
    heading: 'Odebrat zařízení zákazníkovi',
    text: 'Opravdu si přejete odebrat zákazníkovi zařízení "{deviceName}" ({controller})?',
  },
  changeProduct: 'Změnit produkt',
  deviceChangeProduct: {
    success: 'Produkt úspěšně změněn.',
    failure: 'Změna produktu se nezdařila.',
  },
  customerDeleteConfirmation: {
    heading: 'Odstranit zákazníka',
    text: 'Opravdu si přejete odstranit zákazníka {customerName} ({email})?',
  },
  deleteCustomer: {
    success: 'Zákazník úspěšně odstraněn.',
    failure: 'Odstranění zákazníka se nezdařilo.',
  },
  transferDevices: 'Převést zařízení na jiného zákazníka',
  doNotTransferDevices: 'Nepřenášet zařízení',
  productDeleteConfirmation: {
    heading: 'Odstranit produkt',
    text: 'Opravdu si přejete odstranit produkt "{productName}"?',
  },
  deleteProduct: {
    success: 'Produkt úspěšně odstraněn.',
    failure: 'Odstranění produktu se nezdařilo.',
  },
  transferDevicesProduct: 'Převést zařízení na jiný produkt',
  transferToDefaultProduct: 'Převést zařízení na výchozí produkt',
  setAsDefaultProduct: 'Nastavit produkt jako výchozí',
  adminProductCatalogueMenu: {
    companyEligibilities: 'Oprávnění firem',
    deviceTypeEligibilities: 'Oprávnění pro typy zařízení',
    companyAllowedDeviceTypes: 'Povolené typy zařízení pro firmy',
    generalEligibilities: 'Obecná oprávnění',
  },
  companiesThingTypesUpdate: {
    success: 'Povolené typy zařízení úspěšně aktualizovány.',
    failure: 'Aktualizace povolených typů zařízení se nezdařila.',
  },
  allowed: 'Povoleno',
  endUserDefaultValue: 'Výchozí hodnota pro koncového uživatele',
  professionalUserDefaultValue: 'Výchozí hodnota pro profesionálního uživatele',
  generalEligibility: 'Obecné oprávnění',
  updateEligibility: 'Úprava oprávnění',
  eligibility: 'Oprávnění',
  thingTypesEligibilitiesUpdate: {
    success: 'Oprávnění úspěšně aktualizováno.',
    failure: 'Aktualizace oprávnění se nezdařila.',
  },
  companyAllowedEligibilitiesUpdate: {
    success: 'Povolená oprávnění firem úspěšně aktualizovány.',
    failure: 'Aktualizace povolených oprávnění firem se nezdařila.',
  },
  addEligibility: 'Přidat oprávnění',
  deviceEligibilityCreate: {
    success: 'Oprávnění úspěšně vytvořeno.',
    failure: 'Vytvoření oprávnění se nezdařilo.',
  },
  generalEligibilitiesUpdateDefaultValues: {
    success: 'Výchozí hodnoty obecných oprávnění úspěšně aktualizovány.',
    failure: 'Aktualizace výchozích hodnot obecných oprávnění se nezdařila.',
  },
  eligibilityDeleteConfirmation: {
    heading: 'Odstranit oprávnění',
    text: "Opravdu si přejete odstranit oprávnění '{eligibility}'?",
  },
  eligibilityDelete: {
    success: 'Oprávnění úspěšně odstraněno.',
    failure: 'Odstranění oprávnění se nezdařilo.',
  },
  addAccess: 'Přidat přístup',
  addAccessConfirmation: {
    heading: 'Přidat servisní přístup',
    text: 'Opravdu chcete firmě {childCompany} povolit úplný servisní přístup do firmy {parentCompany}?',
  },
  addCurrentCompanyAsServiceCompany: {
    success: 'Firma úspěšně přidána.',
    failure: 'Přidání firmy se nezdařilo.',
  },
  removeAccess: 'Odebrat přístup',
  removeAccessConfirmation: {
    heading: 'Přidat servisní přístup',
    text: 'Opravdu chcete firmě {childCompany} odstranit servisní přístup k zařízením firmy {parentCompany}?',
  },
  removeCurrentCompanyAsServiceCompany: {
    success: 'Servisní přístup úspěšně odstraněn.',
    failure: 'Odstranění servisního přístupu se nezdařilo.',
  },
  addMyselfToCompanyConfirmation: {
    heading: 'Přidat administrátorský přístup do firmy',
    text: 'Jste si jisti, že si chcete sám sobě udělit administrátorský přístup k firmě {companyName}?',
  },
  addMyselfToCompany: {
    success: 'Administrátorský přístup úspěšně udělen.',
    failure: 'Udělení administrátorského přístupu se nezdařilo.',
  },
  removeMyselfFromCompanyConfirmation: {
    heading: 'Odebrat přístup do firmy',
    text: 'Jste si jisti, že si chcete odebrat vlastní přístup do společnosti {companyName}?',
  },
  removeMyCompanyAccess: {
    success: 'Přístup úspěšně odstraněn.',
    failure: 'Odstraněníní přístupu se nezdařilo.',
  },
  invitationState: {
    accepted: 'PŘIJATA',
    expired: 'EXPIROVÁNA',
    pending: 'ČEKÁ NA PŘIJETÍ',
  },
  createCompanyInvitationDeleteConfirmation: {
    heading: 'Odstranit pozvánku',
    text: 'Opravdu chcete odstrabit pozvánku odeslanou na {email}?',
  },
  createCompanyInvitationDelete: {
    success: 'Pozvánka úspěšně odstraněna.',
    failure: 'Odstranění pozvánky se nezdařilo.',
  },
  resendCooldownTooltip: 'Akce bude k dispozici znovu za {remainingTime} sekund.',
  newElegibilityWarning:
    'Nové oprávnění. Toto oprávnění neexistovalo, když byl produkt vytvořen a proto má výchozí hodnotu. Toto upozornění zmizí po aktualizaci produktu.',
  invitationLanguage: 'Jazyk pozvánky',
  userAccessesTab: {
    deviceAdmin: 'Administrátor zařízení',
    guests: 'Hosté',
    demoTokens: 'Demo tokeny',
    guest: 'Host',
    activeDeviceAdminInvitation: 'Pozvánka administrátora odeslána.',
    activeInvitation: 'Pozvánka odeslána.',
    noDeviceAdmin: 'Zařízení nemá v současné době administrátora.',
    noGuests: 'Žádní hosté.',
    noTokens: 'Žádné tokeny.',
    inviteUser: 'Pozvat uživatele',
    changeDeviceAdmin: 'Převést zařízení na nového administrátora',
    changeDeviceAdminModal: {
      headline: 'Převést zařízení na nového administrátora',
      text: 'Totro okamžitě změní vaši roli na hosta a pošle email s pozvánkou novému administrátorovi.',
      selectExistingUser: 'Vybrat nového administrátora z hostů.',
      enterEmail: 'Zadejte e-mailovou adresu.',
    },
    confirmChangeDeviceAdminModal: {
      headline: 'Opravdu chcete převést zařízení na nového administrátora?',
      text: 'Opravdu chcete převést administrátorskou roli k zařízení {deviceName} na uživatele s emailem {invitationEmail}? Tato akce okamžitě změní vaši roli na hosta a pošle email s pozvánkou novému administrátorovi.',
    },
    inviteUserModal: { headline: 'Pozvat nového uživatele' },
    confirmDeleteAccessModal: {
      headline: 'Odstranit přístup uživatele',
      text: 'Opravdu chcete odstranit přístup uživatele s mailem {email} k zařízení {deviceName}?',
    },
    editModal: {
      text: 'Při změně role z hosta na administrátora bude předchozí administrátor měněn na hosta. Zařízení může mít pouze jednoho administrátora.',
      role: 'Role',
      removeUsersAccess: 'Odstranit přístup',
    },
    removeOwnAccessModal: {
      headline: 'Odstranit můj přístup',
      text: 'Chcete převést roli admninistárota zařízení na někoho jiného?',
      deleteWithTransfer: 'Vybrat uživatele',
      deleteWithoutTransfer: 'Odstranit bez převodu',
      selectExistingUser: 'Vybrat nového administrátora z hostů',
      enterEmail: 'Zadat email',
    },
    confirmRemoveOwnAccessModal: {
      headline: 'Odstranit můj přístup',
      text: 'Opravdu chcete odstranit svůj přístup k zařízení "{deviceName}"? Role administrátora bude převedena na {invitationEmail}.',
    },
    removeWithoutTransferConfirmationModal: {
      headline: 'Odstranit můj přístup bez převodu',
      text: "Opravdu chcete odstranit svůj přístup k zařízení, '{deviceName}', aniž byste převedli roli administrátora na jiného uživatele?",
    },
    addDemoTokenModal: {
      headline: 'Přidat demo token',
      submitBtnText: 'Přidat token',
    },
    inviteUserAction: {
      success: 'Uživatel úspěšně pozván.',
      failure: 'Odeslání pozvánky uživateli se nezdařilo.',
      alreadyInvited: 'Uživatel je již pozván.',
      alreadyHasAccess: 'Uživatel již má přístup.',
    },
    transferDeviceAdminAction: {
      success: 'Nový administrátor zařízení úspěšně pozván.',
      failure: 'Převod zařízení na nového administrátora se nezdařil.',
      alreadyDeviceAdmin: 'Uživatel s tímto e-mailem je již administrátorem zařízení.',
    },
    deleteUserAccessAction: {
      success: 'Přístup uživatele úspěšně smazán.',
      failure: 'Odstranění uživatelského přístupu se nezdařilo.',
    },
    changeAccessTypeAction: {
      success: 'Role uživatele úspěšně aktualizována.',
      failure: 'Aktualizace role se nezdařila.',
    },
    createDemoTokenAction: {
      success: 'Demo token úspěšně vytvořen.',
      failure: 'Vytvoření demo tokenu se nezdařilo.',
    },
    wifiPassword: 'Přihlašovací údaje Wi-Fi',
  },
  troubleshooting: 'Řešení chyb',
  possibleCauses: 'Možné příčiny',
  correctiveMeassures: 'Nápravná opatření',
  changeServiceCompanyModal: {
    title: 'Změnit servisní společnost',
    description:
      'Zadejte email administrátora zvané firmy. Na tento email bude zaslána pozvánka. Vaše servisní společnost se změní, pouze pokud společnost přijme vaše pozvání.',
    activeInvitationDescription:
      'Toto zařízení má aktivní pozvánku pro novou servisní firmu. Pokud chcete poslat pozvánku jiné firmě, musíte stávající pozvánku smazat.',
    submit: 'Odeslat pozvánku',
    resendInvitation: 'Odeslat znovu',
    deleteInvitation: 'Odstranit pozvánku',
    activeInvitation: 'Aktivní pozvánka',
    invitationWillExpire: 'Pozvánce skončí platnost',
    inviteServiceCompanyAction: {
      success: 'Nová servisní firma úspěšně pozvána.',
      failure: 'Odesláni pozvánky servisní firmě se nezdařilo.',
      notAdminEmail: 'Zadaný email nepatří administrátorovi žádné firmy.',
      alreadyServiceCompany: 'Firma je již servisní firmou tohoto zařízení.',
    },
    resendInvitationSuccess: 'Pozvánka úspěšně odeslána znovu.',
    deleteServiceCompanyInvitationAction: {
      success: 'Pozvánka úspěšně smazána.',
      failure: 'Odstranění pozvánky se nezdařilo.',
    },
  },
  serviceCompanyInvitationAcceptationModal: {
    title: 'Pozvánka k servisnímu přístupu k zařízení',
    step1: {
      title: 'Přijmout nebo odmítnout',
      accepted: 'Přijato',
      description:
        'Vaše společnost, {companyName}, byla pozvána uživatelem s emailem {invitationFromEmail} k správě zařízení {deviceName} ({controller}). Chcete-li pozvání přijmout, klikněte na Pokračovat a vyplňte požadované údaje.',
      rejectInvitation: 'Odmítnout pozvánku',
    },
    step2: {
      title: 'Vybrat zákazníka',
      createNewCustomer: 'Vytvořit zákazníka',
      submitBtn: 'Dokončit',
    },
    rejectInvitationAction: {
      success: 'Pozvánka úspěšně zamítnuta.',
      failure: 'Zamítnutí pozvánky se nezdařilo.',
    },
    acceptInvitationAction: {
      success: 'Pozvánka úspěšně přijata.',
      failure: 'Přijatí pozvánky se nezdařilo.',
    },
    addProductCataloguePermissionAction: {
      success:
        'Byla vám přiřazena oprávnění k katalogu produktů, abyste mohli dokončit tuto operaci.',
      failure: 'Přijetí pozvánky se nezdařilo.',
    },
  },
  eventCode: 'Kód události',
  events: 'Události',
  detectionPoint: 'Místo detekce',
  detectionPoints: 'Místa detekce',
  coreCause: 'Zdroj problému',
  coreCauses: 'Zdroje problému',
  correctiveAction: 'Nápravné opatření',
  correctiveActions: 'Nápravná opatření',
  nameTakenError: 'Název je již použit.',
  adminEventCode: {
    severity: 'Závažnost',
    description: 'Popis',
    disabled: 'Deaktivován',
    active: 'Aktivní',
    detectionPoint: 'Místo detekce',
    detectionMechanism: 'Mechanismus detekce',
    terminationPoint: 'Místo ukončení',
    terminationMechanism: 'Mechanismus ukončení',
    actionUponDetection: 'Akce při detekci',
    availableCauses: 'Dostupné zdroje problému',
    assignedCauses: 'Přiřazené příčiny',
  },
  adminEventCodes: {
    addEventCode: 'Přidat kód události',
    createEventCodeAction: {
      success: 'Kód události úspěšně vytvořen.',
      failure: 'Vytvoření kódu události se nezdařilo.',
    },
  },
  adminDetectionPoints: {
    addDetectionPoint: 'Přidat místo detekce',
    updateDetectionPoint: 'Aktualizovat místo detekce',
    createDetectionPointAction: {
      success: 'Místo detekce úspěšně vytvořeno.',
      failure: 'Vytvoření místa detekce se nezdařilo.',
    },
    deleteConfirmationModal: {
      heading: 'Odstranit místo detekce',
      text: "Opravdu chcete místo detekce '{detectionPoint}'?",
    },
    deleteDetectionPointAction: {
      success: 'Místo detekce úspěšně vytvořeno.',
      failure: 'Odstranění místa detekce se nezdařilo.',
    },
    updateDetectionPointAction: {
      success: 'Místo detekce úspěšně aktualizováno.',
      failure: 'Aktualizace místa detekce se nezdařila.',
    },
  },
  adminCoreCauses: {
    addCoreCause: 'Přidat zdroj problému',
    createCoreCauseAction: {
      success: 'Zdroj problému úspěšně vytvořen.',
      failure: 'Vytvoření zdroje problému se nezdařilo.',
    },
  },
  adminCorrectiveActions: {
    addCorrectiveAction: 'Přidat nápravné opatření',
    createCorrectiveActionAction: {
      success: 'Nápravné opatření úspěšně vytvořeno.',
      failure: 'Vytvoření nápravného opatření se nezdařilo.',
    },
    deleteConfirmationModal: {
      heading: 'Odstranit nápravné opatření',
      text: "Opravdu chcete odstranit nápravné opatření '{correctiveAction}'?",
    },
    deleteCorrectiveActionAction: {
      success: 'Nápravné opatření úspěšně vytvořeno.',
      failure: 'Odstranění nápravného opatření se nezdařilo.',
    },
    updateCorrectiveActionAction: {
      success: 'Nápravné opatření úspěšně aktualizováno.',
      failure: 'Aktualizace nápravného opatření se nezdařila.',
    },
  },
  eventCodeDetail: {
    title: 'Detail kódu události',
    editAll: 'Upravit vše',
    addCoreCause: 'Přidat zdroj problému',
    eventCodeNotFound: 'Kód události nenalezen.',
    assignCoreCauseAction: {
      success: 'Zdroj problému úspěšně přiřazen.',
      failure: 'Přiřazení zdroje problému se nezdařilo.',
    },
    reorderCoreCausesAction: {
      success: 'Pořadí zdrojů problému úspěšně změněno.',
      failure: 'Změna pořadí zdrojů problému se nezdařila.',
    },
    deleteConfirmationModal: {
      heading: 'Odstranit kód událostí',
      text: "Opravdu chcete odstranit kód události '{eventCode}'?",
    },
    deleteEventCodeAction: {
      success: 'Kód události úspěšně odstraněn.',
      failure: 'Odstranění kódu události se nezdařilo.',
    },
    unassignCoreCauseConfirmationModal: {
      heading: 'Zrušit přiřazení zdroje problému',
      text: "Opravdu chcete zrušit přiřazení zdroje problému '{coreCause}'?",
    },
    unassignCoreCauseAction: {
      success: 'Přiřazení zdroje problému úspěšně zrušeno.',
      failure: 'Zrušení přiřazení zdroje problému se nezrařilo.',
    },
    updateEventCodeModal: { heading: 'Aktualizovat kód události' },
    updateEventCodeAction: {
      success: 'Kód události úspěšně aktualizován.',
      failure: 'Aktualizace kódu události se nezdařila.',
    },
  },
  eventCorrectiveActionDetail: {
    title: 'Detail nápravného opatření',
    assignedToCoreCauses: 'Přiřazen k těmto zdrojům problémům',
    correctiveActionNotFound: 'Nápravné opatření nenalezeno.',
  },
  eventDetectionPointDetail: {
    title: 'Detail bodu detekce',
    assignedToEventCodes: 'Přiřazen k těmto kódům událostí',
    detectionPointNotFound: 'Bod detekce nenalezen.',
  },
  eventCoreCauseDetail: {
    title: 'Podrobnost zdroje problému',
    addCorrectiveAction: 'Přidat nápravné opatření',
    coreCauseNotFound: 'Zdroj problému nenalezen.',
    availableActions: 'Dostupná nápravná opatření',
    assignedActions: 'Přiřazená nápravná opatření',
    assignedToEventCodes: 'Přiřazen k těmto kódům událostí',
    assignCorrectiveActionAction: {
      success: 'Nápravné opatření úspěšně přiřazeno.',
      failure: 'Přiřazení nápravného opatření se nezdařilo.',
    },
    reorderCorrectiveActionsAction: {
      success: 'Pořadí nápravných opatření úspěšně změněno.',
      failure: 'Změna pořadí nápravných opatření se nezdařila.',
    },
    deleteConfirmationModal: {
      heading: 'Odstranit zdroj problému',
      text: "Opravdu chcete odstranit zdroj problému '{coreCause}'?",
    },
    deleteCoreCauseAction: {
      success: 'Zdroj problému úspěšně odstraněn.',
      failure: 'Odstranění zdroje problému se nezdařilo.',
    },
    unassignCorrectiveActionConfirmationModal: {
      heading: 'Zrušit přiřazení nápravného opatření',
      text: "Opravdu chcete zrušit přiřazení nápravného opatření '{correctiveAction}'?",
    },
    unassignCorrectiveActionAction: {
      success: 'Přiřazení nápravného zařízení úspěšně zrušeno.',
      failure: 'Zrušení přiřazení nápravného opatření se nezdařilo.',
    },
    updateCoreCauseAction: {
      success: 'Zdroj problému úspěšně aktualizován.',
      failure: 'Aktualizace zdroje problému se nezdařila.',
    },
  },
  demoTokenTile: {
    token: 'Token',
    tokenValidUntil: 'do',
    expired: 'vypršela platnost',
    neverExpires: 'nikdy nevyprší',
  },
  demoTokenForm: {
    tokenExpiration: 'Platnost tokenu',
    accessExpiration: 'Platnost přístupu',
    neverExpires: 'nikdy nevyprší',
    expirationDate: 'Datum vypršení',
  },
  updateDemoTokenModal: {
    headline: 'Upravit demo token',
    updateDemoTokenAction: {
      success: 'Demo token úspěšně aktualizován.',
      failure: 'Aktualizace demo tokenu se nezdařila',
    },
    deleteDemoTokenAction: {
      success: 'Demo token úspěšně odstraněn.',
      failure: 'Odstranění demo tokenu se nezdařilo.',
    },
  },
  demoTokenDeleteConfirmation: {
    headline: 'Odstranit demo token',
    text: "Opravdu chcete odstranit demo token '{tokenName}'",
  },
  noDevicesText: 'Nemáte žádná zařízení. Začněte přidáním zařízení v menu.',
  demoQrPrintTitle: 'Váš demo token Poolmatix',
  crowdin: {
    crowdin: 'Crowdin',
    uploadTranslations: 'Odeslat nové zdroje na Crowdin',
    downloadTranslations: 'Stáhnout nové překlady z Crowdin',
    updateBase: 'Nastavit anglické překlady jako nový základ',
    confirmationDialog: 'Potvrzovací dialog',
    uploadConfirmationText:
      'Opravdu chcete nahrát nové anglické zdrojové soubory na Crowdin z tohoto prostředí?',
    downloadConfirmationText:
      'Opravdu chcete stáhnout nové překlady z Crowdinu do tohoto prostředí? Ujistěte se, že v tomto prostředí nebyly žádné nedávné změny, které by nebyly nahrány do Crowdin. Pokud existují nějaké změny, které ještě nebyly nahrány na Crowdin, měli byste to udělat nejdříve. V opačném případě můžete přijít o nové změny.',
    updateBaseConfirmationText:
      'Jste si jisti, že chcete nastavit nové české překlady v Crowdin jako nové základní (zdrojové) soubory v Crowdin?',
    crowdinActionSuccess: 'Akce úspěšně dokončena.',
    crowdinActionFailure: 'Akce se nezdařila.',
    crowdinActionPartialFailure: 'Akce se částečně nezdařila. Viz výstup.',
    downloadOnlyDatabase: 'Pouze stáhnout řetězce pouze do databáze, nevytvářet pull request',
  },
  notificationDispatcher: {
    notificationDispatcher: 'Dispečer upozornění',
    notificationFlow: 'Tok upozornění',
    delayConfiguration: 'Časování upozornění',
    event: 'Událost',
    notificationClass: 'Třída upozornění',
    firstNotification: 'První upozornění',
    secondNotification: 'Druhé upozornění',
  },
  notificationClasses: {
    NO_NOTIFICATION: '<span style="color: #6B6B6B">ŽÁDNÉ UPOZORNĚNÍ</span>',
    END_USER_ONLY: 'pouze <span style="color: #1F97FF">KONCOVÝ UŽIVATEL</span>',
    END_USER_THEN_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">KONCOVÝ UŽIVATEL</span> potom <span style="color: #1867C1">PROFESIONÁLNÍ UŽIVATEL</span>',
    END_USER_AND_PROFESSIONAL_USER:
      '<span style="color: #1F97FF">KONCOVÝ UŽIVATEL</span> a <span style="color: #1867C1">PROFESIONÁLNÍ UŽIVATEL</span>',
    PROFESSIONAL_USER_THEN_END_USER:
      '<span style="color: #1867C1">PROFESIONÁLNÍ UŽIVATEL</span> potom <span style="color: #1F97FF">KONCOVÝ UŽIVATEL</span>',
    PROFESSIONAL_USER_ONLY: 'pouze <span style="color: #1867C1">PROFESIONÁLNÍ UŽIVATEL</span>',
  },
  defaultNotificationClass: 'Výchozí třída upozornění',
  updateNotificationClassDelays: {
    success: 'Časování upozornění úspěšně aktualizováno.',
    failure: 'Aktualizace časování upozornění se nezdařilo.',
  },
  serviceSequenceWizard: { deviceWentOffline: 'Zařízení se odpojilo.' },
  abortServiceSequenceConfirmationModal: {
    heading: 'Přerušit servisní sekvenci',
    text: 'Opravdu chcete přerušit běžící servisní sekvenci?',
  },
  besgoValveBackwash: {
    title: 'Zpětný proplach filtru - ventil Besgo',
    idle: 'Tato operace provede proplach filtru. Chcete pokračovat?',
    running: 'Provádí se sekvence proplachu filtru.',
    completed: 'Sekvence proplachu filtru dokončena. (Automaticky zmizí za 2 minuty).',
    failed: 'Sekvence proplachu filtru se nezdařila.',
  },
  notResponding: 'Zařízení neodpovídá.',
  companyManagementMenu: {
    users: 'Uživatelé',
    serviceCompanies: 'Servisní společnosti',
    notifications: 'Upozornění',
    settings: 'Nastavení',
  },
  companyDetail: {
    contactServicePartnerEmailTemplate: 'Šablona emailu pro kontaktování servisního partnera',
    emailAddressesForContactEmails: 'Cílové emaily pro "Kontaktovat servis"',
    emailAddressesForEventNotifications: 'Cílové emaily pro poruchová hlášení',
    targetAddresses: 'Cílové adresy',
    targetAddressesHint: 'Vyberte z možností nebo přidejte nové stisknutím Enter.',
    emailSubject: 'Předmět zprávy',
    emailBody: 'Tělo emailu',
    emailPreview: 'Náhled e-mailu',
    emailVariables: 'Pole e-mailu',
    emailVariablesDescription:
      'V emailové šabloně můete použít tato pole. Pole budou vyplněna skutečnými hodnotami.',
    invalidEmailAddresses: 'Zadejte platné e-mailové adresy.',
    restoreDefaults: 'Načíst znovu výchozí šablonu e-mailu',
    restoreDefaultsConfirmationModal: {
      heading: 'Načíst znovu výchozí šablonu e-mailu',
      text: 'Opravdu chcete obnovit výchozí předmět zprávy a těla emailu?',
    },
    updateCompanyData: {
      success: 'Údaje o společnosti úspěšně aktualizovány.',
      failure: 'Aktualizace údajů společnosti se nezdařila.',
    },
    createNewEmail: 'Vytořit',
  },
  myAccount: 'Můj účet',
  changePassword: 'Změnit heslo',
  currentPassword: 'Současné heslo',
  newPassword: 'Nové heslo',
  newPasswordAgain: 'Zopakujte nové heslo',
  passwordChangeAction: {
    success: 'Heslo úspěšně aktualizováno.',
    failure: 'Aktualizace hesla se nezdařila.',
    notAuthorized: 'Nesprávné heslo.',
  },
  acceptDeviceAccessRequest: {
    headline: 'Přijmout žádost o přístup k zařízení',
    success: 'Úspěšně jste uživateli {userEmail} udělili přístup k zařízení "{deviceName}".',
    invalidLink: 'Odkaz je neplatný.',
    requestNotFound: 'Odkaz je neplatný nebo vypršel.',
    failure: 'Nepodařilo se přijmout přístup k zařízení.',
  },
  descriptions: 'Popisy',
  overview: 'Přehled',
  companyDefaultLanguage: 'Jazyk firmy',
  serviceSequenceSendCommandFailure: 'Odaslání příkazu do zařízení se nezdařilo.',
  updateCompanyContactEmails: {
    success: 'Kontaktní údaje firmy úspěšně aktualizovány.',
    failure: 'Aktualizace kontaktních e-mailů společnosti se nezdařila.',
  },
  updateCompanyContactEmailTemplate: {
    success: 'Šablona kontaktního e-mailu společnosti úspěšně aktualizována.',
    failure: 'Aktualizace šablony kontaktního e-mailu společnosti se nezdařila.',
  },
  contactEmailTemplate: 'Šablona kontaktního e-mailu',
  useDefaultConfiguration: 'Přepnout do nastavení',
  defaultConfiguration: 'Výchozí',
  customConfiguration: 'Individuální',
  companySwitchToDefaultSettings: {
    success: 'Úspěšně přepnuto do výchozího nastavení.',
    failure: 'Přepnutí do výchozího nastavení se nezdařilo.',
  },
  companySwitchToCustomSettings: {
    success: 'Úspěšně přepnuto do individuálního nastavení.',
    failure: 'Přepnutí na individuální nastavení se nezdařilo.',
  },
  defaultValue: 'Výchozí hodnota',
  restoreDefaultNotificationDelaysConfirmationModal: {
    heading: 'Obnovit výchozí časování upozornění',
    text: 'Opravdu chcete obnovit výchozí nastavení časování upozornění? Všechna aktuální nastavení budou ztracena.',
  },
  restoreDefaultNotificationClassesConfirmationModal: {
    heading: 'Obnovit výchozí časování upozornění',
    text: 'Opravdu chcete obnovit výchozí třídy upozornění? Všechny aktuální třídy upozornění budou ztraceny.',
  },
  updateNotificationClassForCompanyAndEventCode: {
    success: 'Třída upozornění úspěšně aktualizována.',
    failure: 'Aktualizace třídy upozornění se nezdařila.',
  },
  timezone: 'Časové pásmo',
  changeOperatingModeModal: {
    heading: 'Provozní režim',
    deviceIsOffline: 'Zařízení je offline, nelze aktualizovat provozní režim.',
    serviceSequenceInProgress:
      'Operating mode cannot be updated. A service sequence is in progress.',
  },
  changeOperatingModeConfirmationModal: {
    heading: 'Provozní režim',
    text: 'Opravdu chcete aktualizovat provozní režim?',
  },
  updateOperatingModeAction: {
    success: 'Provozní režim úspešně aktualizován.',
    failure: 'Aktualizace provozního režimu se nezdařila.',
  },
  predefinedSchedule: 'Systémový rozvrh',
  mySchedule: 'Můj rozvrh',
  editingGlobalScheduleWarning: 'Úprava systémového rozvrhu vytvoří vaši novou kopii rozvrhu.',
  newTimeSchedule: {
    daily: 'Nový denní časový rozvrh',
    weekly: 'Nový týdenní časový rozvrh',
  },
  timeScheduleName: 'Název časového rozvrhu',
  timeScheduleBaseTemplate: 'Výchozí časový rozvrh',
  editDailyTimeScheduleName: 'Upravit název denního časového rozvrhu',
  editWeeklyTimeScheduleName: 'Upravit název týdenního časového rozvrhu',
  enterDailyTimeScheduleName: 'Zadejte název denního rozvrhu',
  enterWeeklyTimeScheduleName: 'Zadejte název týdenního rozvrhu',
  weeklyTimeScheduleDeleteConfirmationHeading: 'Odstranit týdenní rozvrh',
  weeklyTimeScheduleDeleteConfirmationText:
    "Opravdu chcete odstranit týdenní rozvrh '{scheduleName}'?",
  newWeeklyTimeScheduleDeleteConfirmationHeading: 'Odstranit nový týdenní rozvrh',
  newWeeklyTimeScheduleDeleteConfirmationText: 'Opravdu chcete smazat tento nový týdenní rozvrh?',
  dailyTimScheduleDeleteConfirmationHeading: 'Odstranit denní rozvrh',
  dailyTimScheduleDeleteConfirmationText: "Opravdu chcete odstranit denní rozvrh '{scheduleName}'?",
  newDailyTimScheduleDeleteConfirmationHeading: 'Odstranit nový denní rozvrh',
  newDailyTimScheduleDeleteConfirmationText: 'Opravdu chcete smazat tento nový denní rozvrh?',
  addDailySchedule: 'Přidat denní časový rozvrh',
  weeklyTimeSchedules: 'Týdenní časový rozvrh',
  dailyTimeSchedules: 'Denní časové rozvrhy',
  weeklyTimeSchedule: 'Týdenní časové rozvrhy',
  dailyTimeSchedule: 'Denní časový rozvrh',
  disabledDeleteTooltipForScheduleGlobal: 'Nelze odstranit systémový časový rozvrh.',
  disabledDeleteTooltipForDailyScheduleUsed:
    'Nelze odstranit používaný denní časový rozvrh. Tento denní časový rozvrh se používá v některém týdenním časovém rozvrhu. Nejprve prosím odstraňte denní rozvrh ze všech týdenních časových rozvrhů.',
  disabledDeleteTooltipForWeeklyActive:
    'Nelze odstranit aktivní týdenní časový rozvrh. Tento týdenní časový rozvrh je právě aktivní. Nejprve prosím aktivujte jiný týdenní časový rozvrh.',
  disabledDeleteTooltipForWeeklyLast:
    'Nelze odstranit poslední zbývající týdenní časový rozvrh. Musí existovat alespoň jeden týdenní časový rozvrh. Nejprve prosím vytvořte nový týdenní časový rozvrh.',
  disabledDeleteTooltipForWeeklyUsed:
    'Nelze odstranit používaný týdenní časový rozvrh. Tento týdenní časový rozvrh je používán některými zařízeními. Nejprve prosím zajistěte, aby tento týdenní časový rozvrh nebyl používán žádnými zařízeními.',
  showMore: 'Zobrazit více',
  showLess: 'Zobrazit méně',
  createServiceHistoryEntry: {
    success: 'Záznam úspěšně vytvořen.',
    failure: 'Vytvoření záaznamu se nezdařilo.',
  },
  customNotificationClasses: 'Individuální třídy upozornění',
  noCustomClasses: 'Žádné individuální třídy upozornění',
  createCustomNotificationClass: 'Vytvořit individuální třídu upozornění',
  createCustomNotificationClassAction: {
    success: 'Individuální třída upozornění úspěšně vytvořena.',
    failure: 'Vytvoření individuální třídy upozornění se nezdařilo.',
  },
  deleteCustomNotificationClassAction: {
    success: 'Individuální třída upozornění úspěšně odstraněna.',
    failure: 'Odstranění individuální třídy upozornění se nezdařilo.',
  },
  canNotDeleteUsedCustomNotificationClassTooltip:
    'Nelze odstranit používanou individuální třídu upozornění.',
  deleteCustomNotificationClassConfirmationModal: {
    heading: 'Odstranit individuální třídu upozornění',
    text: "Opravdu chcete odstranit individuální třídu upozornění'{className}'?",
  },
  newErrorNotification: 'Nová systémová událost',
  errorMessageClosed: 'Systémová událost uzavřena',
  loadMore: 'Načíst více',
  backToTop: 'Zpět nahoru',
  backToDashboard: 'Zpět na dashboard',
  backToProductsList: 'Zpět na výchozí produkty',
  serviceHistoryEntryTypes: {
    USER_NOTE: 'Uživatelské zprávy',
    EVENT: 'Systémové události',
    OPERATION_COUNTER_CYCLE: 'Provozní cykly',
    SERVICE_PARTNER_CONTACT: 'Zprávy servisnímu partnerovi',
  },
  filter: 'Filtr',
  discardChanges: 'Zrušit změny',
  continueEditing: 'Pokračovat v úpravách',
  wifiCredentials: 'Přihlašovací údaje Wi-Fi',
  wifiCredentialsNotFound: 'Nelze načíst přihlašovací údaje k Wi-Fi. Kontaktujte prosím podporu.',
  show: 'Zobrazit',
  ssid: 'SSID',
  wifiQrCode: 'Naskenujte QR kód pro připojení z telefonu.',
  iXsolveNotifications: 'Nová systémová událost',
  user: 'Uživatel',
  chargersApiKey: 'API klíč nabíječky',
  noChargerApiKey: 'V současnosti nemáte žádný API klíč.',
  hasChargerApiKey:
    'Váš API klíč je aktivní. Pokud jste ztratil API klíč, vygenerujte si prosím nový.',
  deleteApiKey: 'Odstranit API klíč',
  copyApiKeyWarning: 'Prosím uložte si tento nový API klíč. Není možné jej zobrazit znovu.',
  generateNewApiKey: 'Vygenerovat nový API klíč',
  confirmGenerateNew: 'Odstranit starý API klíč a vygenerovat nový',
  generateNewApiKeyConfirmationModal: {
    heading: 'Vygenerovat nový API klíč nabíječky',
    text: 'Opravdu chcete vygenerovat nový API klíč? Váš aktuální API klíč bude odstraněn.',
  },
  deleteApiKeyConfirmationModal: {
    heading: 'Odstranit API klíč nabíječky',
    text: 'Opravdu chcete odstranit váš API klíč nabíječky?',
  },
  generateNewApiKeyAction: {
    success: 'Nový API klíč nabíječky úspěšně vygenerován.',
    failure: 'Generování nového API klíče nabíječky se nezdařilo.',
  },
  deleteApiKeyAction: {
    success: 'API klíč nabíječky úspěšně odstranněn.',
    failure: 'Odstranění API klíče nabíječky se nezdařilo.',
  },
  energy: 'Spotřeba energie',
  dateRangeChoice: {
    today: 'Dnes',
    yesterday: 'Včera',
    thisWeek: 'Tento týden',
    lastWeek: 'Minulý týden',
    thisMonth: 'Tento měsíc',
    lastMonth: 'Minulý měsíc',
    last30days: 'Posledních 30 dní',
    last90days: 'Posledních 90 dní',
    thisYear: 'Letos',
    lastYear: 'Loni',
  },
  datesSelected: 'Vybráno',
  energyChartsNoData: 'Nedostatečná data pro zvolené časové období.',
  hourCounter: 'Počet hodin',
  currentHourCounter: 'Aktuální počet hodin',
  newHourCounter: 'Nový počet hodin',
  startCounter: 'Počet startů',
  currentStartCounter: 'Aktuální počet startů',
  newStartCounter: 'Nový počet startů',
  cycleStartDate: 'Začátek aktuálního cyklu',
  newCycleStart: 'Začátek nového cyklu',
  newOperatingCycle: 'Nový provozní cyklus',
  startNewCycle: 'Začít nový cyklus',
  equipmentId: 'Aktuální ID spotřebiče',
  newEquipmentId: 'Nové ID spotřebíče',
  equipmentType: 'Typ spotřebiče',
  operationCounterNotePlaceholder: 'Uživatelská poznámka',
  operationCounterCycleCreateAction: {
    success: 'Nový provozní cyklus úspěšně vytvořen.',
    failure: 'Vytvoření nového provozního cyklu se nezdařilo.',
  },
  noOperationCounters: 'Není k dispozici žádné provozní počítadlo.',
  serviceOrderNumber: 'Objednávka č.',
  assignee: 'Přiřazena',
  createdBy: 'Vytvořil',
  createdAt: 'Datum a čas',
  serviceOrderStatuses: {
    OPEN: 'Vytvořená',
    IN_PROGRESS: 'Rozpracovaná',
    CLOSED: 'Uzavřená',
  },
  serviceOrderText: 'Text',
  createServiceOrder: 'Vytvořit servisní objednávku',
  createServiceOrderAction: {
    success: 'Servisní objednávka úspěšně vytvořena.',
    failure: 'Vytvoření servisní objednávky se nezdařilo.',
  },
  updateServiceOrderAction: {
    success: 'Servisní objednávka úspěšně aktualizována.',
    failure: 'Aktualizace servisní objednávky se nezdařila.',
  },
  deleteServiceOrderAction: {
    success: 'Servisní objednávka úspěšně odstraněna.',
    failure: 'Odstranění servisní objednávky se nezdařilo.',
  },
  serviceOrder: 'Servisní objednávka',
  thingData: 'Údaje o zařízení',
  serviceOrderDeleteConfirmation: {
    heading: 'Odstranit servisní objednávku',
    text: "Opravdu chcete odstranit servisní objednávku '{serviceOrderNumber}?",
  },
  emailNotifications: 'Emailová upozornění',
  emailNotificationsAddFirst: 'Zadejte alespoň jednu e-mailovou adresu pro povolení upozornění.',
  smsNotifications: 'SMS upozornění',
  smsNotificationsAddFirst: 'Zadejte alespoň jedno telefonní číslo pro povolení upozornění.',
  pushNotifications: 'Push notifikace',
  pushNotificationsText:
    'Push notifikace jsou odesílány na všechna zařízení s aplikací iXmanager, která používají toto zařízení.',
  deviceSetEventNotificationsAction: {
    success: 'Nastavení upozornění bylo úspěšně aktualizováno.',
    failure: 'Aktualizace nastavení upozornění se nezdařila.',
  },
  energyReports: 'Energetické přehledy',
  configurationJobInProgress: 'Probíhá aktualizace nastavení.',
  dataPropagationFailed: 'Aktualizace nastavení se nezdařila.',
  configurationChanged: 'Změna nastavení vyžaduje restart zařízení.',
  confirmDiscardChangesModal: {
    heading: 'Zrušit změny nastavení.',
    text: 'Opravdu chcete zrušit nastavení, které nebyla odesláno do zařízení? Tato akce také zruší jakékoliv čekající aktualizace nastavení.',
  },
  deviceCancelConfigurationAction: {
    success: 'Aktualizace nastavení byla úspěšně zrušena.',
    failure: 'Zrušení aktualizace nastavení se nezdařilo.',
    inProgressError:
      'Aktualizace nastavení již nemůže být zrušena. Počkejte prosím, než bude dokončena.',
  },
  mode: 'Režim',
  duplicateSchedule: 'Duplikovat',
  duplicateScheduleTooltip: 'Vytvořit nový rozvrh z tohoto rozvrhu',
  repeat: 'Opakovat',
  daily: 'Denní',
  activate: 'Aktivovat',
  dailyScheduleNotUsedError:
    'Každý denní rozvrh musí být použit alespoň jednou v týdenním rozvrhu.',
  everyDayHasToBeUsedError: 'Nastavte prosím denní rozvrh pro každý den v týdnu. Chybějící dny:',
  weeklyScheduleEmptyError: 'Týdenní rozvrh musí obsahovat 1 až 7 denních rozvrhů.',
  scheduleInvalidNameError: 'Prosím zadejte platné jméno rozvrhu.',
  noChangeError: 'Nebyly provedeny žádné změny.',
  newSchedule: 'Nový rozvrh',
  nothingToSeeHereYet: 'Nejsou definovány žádné týdenní rozvrhy.',
  addFirstDailyScheduleMessage: 'Prosím, přidejte svůj první denní rozvrh.',
  saveWithWipModal: {
    heading: 'Neuložený interval',
    text: 'Některé intervaly nebyly uloženy. Vraťte se prosím zpět a dokončete své změny nebo zvolíte uložení plánu s neuloženými intervaly nebo bez nich.',
    saveWithWip: 'Uložit s neuloženými intervaly',
    saveWithoutWip: 'Uložit s vynecháním neuložených intervalů',
  },
  editDailyTimeSchedule: 'Upravit denní rozvrh',
  newWeeklySchedule: 'Přidat týdenní rozvrh',
  newDailySchedule: 'Nový denní rozvrh',
  dailySchedules: 'Denní rozvrhy',
  today: 'Dnes',
  activeWeeklySchedule: 'Aktivní týdenní rozvrh',
  saveDailyConfirmationModal: {
    heading: 'Uložit změny',
    text: 'Denní plán <b>{scheduleName}</b> , který se používá v těchto týdenních plánech. Změny denního plánu ovlivní všechny tyto týdenní plány.',
  },
  duplicateSchedulePostfix: 'kopie',
  new: 'nový',
  selectAll: 'Vybrat vše',
  systemTimeScheduleEditValidationModal: {
    heading: 'Systémový rozvrh nelze upravovat.',
    info: 'Můžete vytvořit vlastní kopii rozvrhu a upravit jej.',
    duplicateAction: 'Vytvořit kopii',
  },
  addProduct: {
    selectDevice: 'Vyberte zařízení',
    selectProduct: 'Vyberte produkt',
    setTemplate: 'Nastavit šablonu',
  },
  cancelSequence: 'Zrušit sekvenci',
  saveAndActivate: 'Uložit a aktivovat',
  xeeloIntegration: 'Integrace Xeelo',
  addXeeloIntegration: 'Přidat integraci Xeelo',
  addXeeloIntegrationConfirmation: {
    heading: 'Přidat integraci Xeelo',
    text: 'Opravdu chcete přidat integraci Xeelo pro firmu {companyName}?',
  },
  addXeeloIntegrationAction: {
    success: 'Integrace Xeelo byla úspěšně přidána.',
    failure: 'Přidání integrace Xeelo se nezdařilo.',
  },
  paymentType: {
    ONE_OFF: 'Jednorázový',
    SUBSCRIPTION: 'Předplatné',
  },
  serviceSequence: 'Servisní sekvence',
  chargingStart: 'Začátek nabíjení',
  duration: 'Doba',
  totalEnergy: 'Celková energie',
  chargingHistoryNoData: 'Žádná data nejsou dostupná.',
  export: 'Export dat',
  exportChargingHistory: 'Exportovat historii nabíjení',
  dateFrom: 'Od',
  dateTo: 'Do',
  chargingHistoryExportFailure: 'Export historie nabíjení se nezdařil.',
  invalidDate: 'Neplatné datum.',
  chargingHistoryDateFromHint:
    'Ponechte prázdné, chcete-li začít od nejstaršího dostupného záznamu.',
  chargingHistoryDateToHint: 'Výchozí datum je dnešek.',
  errorInTimeScheduleConfiguration:
    'Nepodporovaná konfigurace časového plánu. Obraťte se na svého prodejce.',
  errorInTimeScheduleConfigUpdate:
    'Nelze dokončit změnu systémového rozvrhu. Následující zařízení mají konfigurace, které nejsou kompatibilní s novým časovým rozvrhem: {deviceList}',
  eligibilityControl: {
    available: 'Dostupné',
    notAvailable: 'Nedostupné',
    optional: 'Volitelné',
  },
  feature: 'Funkce',
  eligibilityFilter: {
    all: 'Vše',
    oneOff: 'Jednorázové',
    subscription: 'Předplatné',
    serviceSequences: 'Servisní sekvence',
    premium: 'Prémiové',
  },
  creatBaseProduct: 'Vytvořit nový výchozí produkt',
  baseProduct: 'Výchozí produkt',
  selectProductTemplate: '-',
  noTemplateYet: 'Pro tuto skupinu typů zařízení není k dispozici žádný výchozí produkt.',
  createProduct: 'Přidat nový výchozí produkt',
  availability: 'Dostupnost',
  newProductCreate: {
    success: 'Nastavení produktu bylo úspěšně uloženo.',
    failure: 'Uložení nastavení produktu se nezdařilo.',
  },
  newProductUpdate: {
    success: 'Nastavení produktu bylo úspěšně aktualizováno.',
    failure: 'Aktualizace nastavení produktu se nezdařila.',
  },
  addBaseProduct: 'Nový výchozí produkt',
  editBaseProduct: 'Upravit výchozí produkt',
  productSettings: 'Nastavení produktu',
  availabilityBaseProduct: 'Dostupnost ve výchozím produktu',
  defaultAvailability: 'Výchozí dostupnost',
  selectBaseProduct: 'Vyberte výchozí produkt',
  premium: 'Premium',
  newProductCatalogueMenu: {
    thingsPage: 'Zařízení',
    baseProductsPage: 'Výchozí produkt',
  },
  changeProductModal: {
    title: 'Změnit výchozí produkt',
    description: 'Změna výchozího produktu smaže veškerá nastavení produktu zařízení. Pokračovat?',
  },
  thingTypeFamily: 'Skupina typů zařízení',
  premiumFeatureModalTitle: 'Povolení prémiové funkce',
  premiumFeatureModalDesc:
    'Povolujete prémiovou funkci. Mohou se uplatňovat další poplatky. Pokračovat?',
  eligibilityControlTooltip: 'Tato funkce je k dostupná vždy. Výchozí dostupnost nelze změnit.',
  provisionType: 'Typ poskytování',
  updateThingType: 'Aktualizovat typ zařízení',
  thingTypeRelationUpdateSuccess: 'Relace byla úspěšně aktualizována.',
  thingTypeRelationUpdateFail: 'Aktualizace relace se nezdařila.',
  tools: 'Nástroje',
  deleteWeeklyScheduleInfo: 'Týdenní časový rozvrh byl odstraněn.',
  deleteDailyScheduleInfo: 'Denní časový rozvrh byl odstraněn.',
  duplicateWeeklyScheduleInfo: 'Týdenní časový rozvrh byl duplikován.',
  duplicateDailyScheduleInfo: 'Denní časový rozvrh byl duplikován.',
  scheduleDuplicateFailed: 'Smazání časového rozvrhu se nezdařilo.',
  messageToServicePartner: 'Zpráva servisnímu partnerovi',
  newMessage: 'Nová zpráva',
  emailSentTo: 'E-mail zaslaný na adresu',
  queryFailed: 'Při načítání dat došlo k chybě.',
  retry: 'Zkusit znovu',
  newUserMessage: 'Nová uživatelská zpráva',
  userMessage: 'Uživatelská zpráva',
  operatingCycle: 'Provozní cyklus',
  systemEvent: 'Systémová událost',
  dateFormats: {
    minutes: 'MM',
    seconds: 'SS',
    hours: 'HH',
  },
}
