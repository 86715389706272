<template>
  <div>
    <v-btn color="primary" depressed class="mb-4" @click="createEventCodeModalOpen = true">{{
      $t('global.adminEventCodes.addEventCode')
    }}</v-btn>
    <DataTableCustom
      :headers="headers"
      :items="eventCodes"
      :loading="$apollo.loading"
      :search.sync="search"
      :item-class="() => 'row-wrapper'"
      @click:row="
        (item) => {
          $router.push({
            name: 'AdminEventCodeDetail',
            params: { id: item.id },
          })
        }
      "
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      :items-per-page="-1"
      class="elevation-1 pt-2 mb-12"
      multi-sort
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.severity="{ item }">
        <div class="d-flex">
          <ReportBadge :text="item.severity" :severity="item.severity" :clickable="false" />
        </div>
      </template>
      <template v-slot:item.disabled="{ item }">
        <span class="status-badge" :class="item.disabled ? 'offline-badge' : 'online-badge'">{{
          item.disabled ? $t('global.adminEventCode.disabled') : $t('global.adminEventCode.active')
        }}</span>
      </template>
      <template v-slot:item.defaultNotificationClass="{ item }">
        <NotificationClass
          v-if="item.defaultNotificationClass != null"
          :notificationClass="item.defaultNotificationClass.notificationClass"
        />
      </template>
      <template v-slot:item.exclusiveCompany="{ item }">
        <span class="value--text">{{
          item.exclusiveCompanyId == null || item.exclusiveCompanyId == undefined || !companies
            ? '---'
            : companies.find((c) => c.id == item.exclusiveCompanyId)?.name
        }}</span>
      </template>
    </DataTableCustom>

    <v-dialog v-model="createEventCodeModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-6" ref="createModalHeader">
            {{ $t('global.adminEventCodes.addEventCode') }}
          </h2>
          <EventCodeForm ref="eventCodeForm" />
          <div class="mt-8 text-right">
            <v-btn @click="createEventCodeModalOpen = false" depressed>{{
              $t('global.close')
            }}</v-btn>
            <v-btn @click="createEventCode()" color="primary" class="ml-3" depressed>{{
              $t('global.submit')
            }}</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { eventCodesAdmin } from '@/graphql/query/eventCodesAdmin'
import { eventCodeCreate } from '@/graphql/mutations/eventCodeCreate'
import EventCodeForm from '@/components/Admin/ServiceHints/EventCodeForm'
import ReportBadge from '@/components/UI/ReportBadge'
import { allowedLanguages } from '@/translations'
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import { allCompanies } from '@/graphql/query/companies'

export default {
  name: 'AdminEventCodes',
  components: { EventCodeForm, ReportBadge, NotificationClass },
  data() {
    return {
      headers: [
        { text: this.$t('global.eventCode'), value: 'name' },
        { text: this.$t('global.adminEventCode.severity'), value: 'severity' },
        { text: this.$t('global.description'), value: 'description' },
        { text: this.$t('global.state'), value: 'disabled' },
        {
          text: this.$t('global.adminEventCode.detectionPoint'),
          value: 'detectionPoint.name',
        },
        {
          text: this.$t('global.defaultNotificationClass'),
          value: 'defaultNotificationClass',
        },
        {
          text: this.$t('global.exclusiveCompany'),
          value: 'exclusiveCompany',
        },
      ],
      search: '',
      createEventCodeModalOpen: false,
    }
  },
  apollo: {
    eventCodes: {
      query: eventCodesAdmin,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.eventCodesAdmin
      },
    },
    companies: {
      query: allCompanies,
      update(data) {
        return data?.companies || []
      },
    },
  },
  methods: {
    async createEventCode() {
      this.$refs.eventCodeForm.$v.$touch()
      if (!this.$refs.eventCodeForm.$v.$invalid) {
        try {
          const nullInsteadOfEmptyString = (string) => (string?.trim() === '' ? null : string)
          const formData = this.$refs.eventCodeForm.form

          await this.$apollo.mutate({
            mutation: eventCodeCreate,
            variables: {
              data: {
                ...formData,
                detectionMechanism: nullInsteadOfEmptyString(formData.detectionMechanism),
                terminationPoint: nullInsteadOfEmptyString(formData.terminationPoint),
                terminationMechanism: nullInsteadOfEmptyString(formData.terminationMechanism),
                actionUponDetection: nullInsteadOfEmptyString(formData.actionUponDetection),
                descriptionTranslations: Object.fromEntries(
                  allowedLanguages.map((lang) => [
                    lang,
                    nullInsteadOfEmptyString(formData.descriptionTranslations[lang]),
                  ]),
                ),
              },
            },
            refetchQueries: ['eventCodesAdmin'],
          })

          this.$toast.success(this.$t('global.adminEventCodes.createEventCodeAction.success'))
          this.$refs.eventCodeForm.reset()
          this.$refs.createModalHeader.scrollIntoView()
          this.createEventCodeModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(
            parsedError === 'EVENT_CODE_CREATE_NAME_TAKEN'
              ? this.$t('global.nameTakenError')
              : this.$t('global.adminEventCodes.createEventCodeAction.failure'),
          )
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.status-badge {
  font-weight: 500;
  font-size: 11px;
  padding: 8px 11px;
  border-radius: 1px;
  line-height: 11px;

  &.offline-badge {
    background-color: #fc5252;
    color: white;
  }
  &.online-badge {
    background-color: fade(#22b77c, 10%);
    color: #22b77c;
  }
}

/deep/.row-wrapper {
  cursor: pointer;
}
</style>
