<template>
  <div class="eligibility-row">
    <div class="name-column">
      <div class="d-flex flex-wrap">
        <h4 class="mr-2">{{ eligibility.label }}</h4>
        <PaymentTypeBadge :paymentType="eligibility.provisionType" class="mr-2" />
        <ServiceSequenceBadge v-if="eligibility.serviceSequence" class="mr-2" />
        <PremiumBadge v-if="eligibility.isPremium" />
      </div>
      <div>
        <span>{{ eligibility.description }}</span>
      </div>
    </div>
    <div class="availability-column">
      <span :class="`status-indicator ${internalSelectedOption}`"></span>
      <v-select
        v-model="internalSelectedOption"
        :items="options"
        @change="optionChanged"
        dense
        class="eligibility-select"
        hide-details
      ></v-select>
    </div>
    <div class="value-column">
      <CheckboxInput
        v-if="internalSelectedOption === 'optional'"
        v-model="internalDefaultValue"
        :onlyInput="true"
        :name="'default-value-' + eligibility.name"
        @input="optionChanged"
      />
    </div>
  </div>
</template>

<script>
import PaymentTypeBadge from '@/components/UI/PaymentTypeBadge'
import ServiceSequenceBadge from '@/components/UI/ServiceSequenceBadge'
import PremiumBadge from '@/components/UI/PremiumBadge.vue'
import CheckboxInput from '@/components/Form/Input/CheckboxInput'

export default {
  name: 'EligibilityControl',
  components: {
    PaymentTypeBadge,
    ServiceSequenceBadge,
    PremiumBadge,
    CheckboxInput,
  },
  props: {
    eligibility: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({ defaultAvailability: 'available', defaultValue: false }),
    },
  },
  data() {
    return {
      options: [
        { text: this.$t('global.eligibilityControl.available'), value: 'available' },
        { text: this.$t('global.eligibilityControl.notAvailable'), value: 'not_available' },
        { text: this.$t('global.eligibilityControl.optional'), value: 'optional' },
      ],
      internalSelectedOption: this.value.defaultAvailability,
      internalDefaultValue: this.value.defaultValue,
    }
  },
  methods: {
    optionChanged() {
      this.$emit('input', {
        defaultAvailability: this.internalSelectedOption,
        defaultValue: this.internalDefaultValue,
      })
    },
  },
  mounted() {
    console.log('TH', this.value)
  },
  watch: {
    internalSelectedOption() {
      this.optionChanged()
    },
    internalDefaultValue() {
      this.optionChanged()
    },
  },
}
</script>

<style scoped>
.eligibility-row {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f3f6f9;
  border-radius: 8px;
  margin-bottom: 8px;
}

.name-column {
  flex: 1;
  margin-right: 16px;
}

.availability-column {
  display: flex;
  align-items: center;
  flex-basis: 25%;
}

.value-column {
  flex-basis: 6%;
}

.status-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-indicator.available {
  background-color: green;
}

.status-indicator.not_available {
  background-color: gray;
}

.status-indicator.optional {
  background-color: orange;
}

.eligibility-select {
  flex: 1;
  border: none;
  background-color: transparent;
  width: 100%;
  max-width: 11rem;
}

.eligibility-row .value-column .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
